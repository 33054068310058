import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, change, reset } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";


let url = 'devolucion' ;
let form = 'DevolucionForm';
let form2 = 'DevolucionProForm';
let dirGrid = '/devoluciones';
let dirGrid2 = '/devolucionespro';


export const constants ={
    LOADER: 'LOADER_DEVOLUCION',
    DATA: 'DATA_DEVOLUCION',
    ITEM: 'ITEM_DEVOLUCION',
    PAGE: `PAGE_DEVOLUCION`,
    ORDERING: `ORDERING_DEVOLUCION`,
    SEARCH: `SEARCH_DEVOLUCION`,
    OPEN_MODAL: 'OPENMODAL_DEVOLUCION',
    LOADER_FORMULARIO: 'LOADER_FORMULARIO_DEVOLUCION',
    SET_PENDIENTES:'SET_PENDIENTES_DEVOLUCION',
    SET_PRODUCTOSDS:'SET_PRODUCTOS_DEVOLUCION',
    HISTORIA_MOVIMIENTOS: 'SET_HISTORIA_DEVOLUCION',
    SET_EMPRESAS: 'SET_EMPRESAS_DEVOLUCION',
    CLIENTE : 'CLIENTE_DEVOLUCION',
    SET_HISTORY: 'SET_HISTORY',
    SET_TIPO_MOVIMIENTO: 'SET_TIPO_MOVIMIENTO',
    SET_PRODUCTOS: 'SET_PRODUCTOS_ADD',
    SET_CHECK_NOTA_CON_IVA: 'SET_CHECK_NOTA_CON_IVA',
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})
const setLoaderFormulario = loader_formulario =>({
    type:constants.LOADER_FORMULARIO,
    loader_formulario
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresasE').then((res)=>{
        dispatch({type: constants.SET_EMPRESAS, data: res.empresa})
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const addProductos = (producto) => (dispatch, getStore) =>{
    const productos= getStore().devolucion.productos_add;
    productos.push(producto)
    dispatch({type: constants.SET_PRODUCTOS, data: productos})
}
const removeProductos = (producto) => (dispatch, getStore) =>{
    const productos= getStore().devolucion.productos_add;
    productos.pop(producto)
    dispatch({type: constants.SET_PRODUCTOS, data: productos})
}

const listar = (page = 1, bodega) => (dispatch, getStore) => {
    const resource = getStore().devolucion;
    const params = { page };
    params.bodega = bodega
    params.search = resource.search;
    params.tipo_movimiento = resource.tipo_movimiento
    if(resource.tipo_movimiento == 70){
        const me = getStore().login.me
        const visualizar_mis_datos = me.permisos.visualizar_mis_datos
        if(visualizar_mis_datos == true){
            params.visualizar_mis_datos = true
            params.id_user = me.id
        }
    }
    dispatch(setLoader(true));
    dispatch({type: constants.SET_PRODUCTOS, data: []})
    api.get(`despachos`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const getHistoria = (page = 1, bodega) => (dispatch, getStore) => {
    const resource = getStore().estado_bodega;
    const params = { page };
    params.bodega_origen = bodega
    params.search = resource.search;
    dispatch(setLoader(true));

    api.get(`despachos`, params).then((response) => {
        dispatch(setData(constants.HISTORIA_MOVIMIENTOS ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const leer = (id, esEdicion=false, descripcion="", empresa=null, encargado=null) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`despachos/${id}`).then((response) => {
        if(response){
            response.detalle_despacho.forEach(producto =>{
                producto.producto.precio_venta = producto.precio_unitario
            })
        }
        if (!esEdicion) {
            response['depacho_devolucion'] = _.cloneDeep(response);
            response['sucursal'] = response.orden.direccion;
            response['cliente'] = response.cliente_orden;
            response['fecha'] = new Date();
            response['descripcion'] = descripcion;
            response['empresa'] = empresa;
            response['encargado'] = encargado;
        }
        dispatch(setData(constants.ITEM,response));
        if (!!form){
            if(response.tipo_movimiento == 75 ){
                dispatch(initializeForm(form2, response));
            }else{
                dispatch(initializeForm(form, response));
            }
        }

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const inicializarDespacho = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`despachos/${id}`).then((response) => {
        console.log('Response, ', response);
        let detalle = [];
        let cantidad = 0;
        for (const item of response.detalle_despacho){
            if (item.tipo_devolucion != 30){
                cantidad = item.cantidad
                item.cantidad = item.cantidad - item.cantidad_despachada
                detalle.push({...item, requerido: cantidad});

            }
        }
        response.Despachos = detalle;
        dispatch(setData(constants.ITEM,response));
        if (!!form)
            dispatch(initializeForm('DespachoForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        if (data.es_proveedor){
            dispatch(push(dirGrid2))
        }else{
            dispatch(push(dirGrid))
        }
    }).catch((e) => {
        const error = e && e.detail ? e.detail : 'Error en la creación'
        NotificationManager.error(error, 'ERROR');
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false))
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false))
    });
};

const aceptarDevoluciones = data => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`devolucion/aceptar_devolucion`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        if (data.es_proveedor){
            dispatch(push(dirGrid2))
        }else{
            dispatch(push(dirGrid))
        }
    }).catch(() => {
        let mensaje = 'Error en la devolución';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false));
        dispatch(initializeForm(form, {}));
    });
};

const aceptarDevolucionesProv = data => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`devolucion/aceptar_devolucion_proveedor`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        if (data.es_proveedor){
            dispatch(push(dirGrid2))
        }else{
            dispatch(push(dirGrid))
        }
    }).catch((error) => {
        let mensaje = 'Error en la devolución';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setOpenModal(false));
        dispatch(initializeForm(form, {}));
    });
};

const rechazarDevolucion = (id, proveedor=true) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`devolucion/rechazar_devolucion`, {id}).then((response) => {
        NotificationManager.success('Devolución rechazada', 'Éxito', 3000);
        if (proveedor){
            dispatch(push(dirGrid2))
        }else{
            dispatch(push(dirGrid))
        }
    }).catch((error) => {
        let mensaje = 'Error al rechazar la devolución';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const realizarDespachos = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`devolucion/despacho_devolucion/`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push(dirGrid));
    }).catch((error) => {
        let mensaje = 'Error en la devolución';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setOpenModal(false));
        dispatch(setLoader(false));
        dispatch(initializeForm(form, {}));
    });
};

const realizarDespachosProv = data => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`devolucion/despacho_devolucion_prov/`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push(dirGrid2));
    }).catch(() => {
        let mensaje = 'Error en la devolución';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setOpenModal(false));
        dispatch(setLoaderFormulario(false));
        dispatch(initializeForm(form, {}));
        dispatch(initializeForm(form2, {}));
    });
};

const HistoryDispatch = (id) => (dispatch, getStore) => {
    const params = { };

    dispatch(setLoader(true));
    api.get(`devolucion/${id}/historialdespacho/`, params).then((res) => {
      
        dispatch(setData(constants.SET_HISTORY, res))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const reajustar_inventario = (data, bodega) => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`despachos`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(listar(1, bodega));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false))
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());

    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
    });
};

const searchChange = (search, bodega) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar(1, bodega));
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


const setOpenModal = (open_modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL,
        open_modal: open_modal
    })
}

const listDS = (id_bodega) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {id_bodega}
    api.get(`despachos/listds`, params).then((res)=>{
        dispatch(setData(constants.SET_PENDIENTES, res))
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const clearDespacho = () => (dispatch) => {
    dispatch(change('DevolucionProForm', 'depacho_devolucion', null));
}

const setProductoDespacho = (parametros) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {...parametros}
    api.get(`despachos/getListadepacho`, params).then((res)=>{
        dispatch(setData(constants.SET_PRODUCTOSDS, res))
        let detalle = []
        res.forEach( (item, index) => {
            detalle[index] = {
                producto: item,
                despachar: item.es_bodega? item.cantidad_actual: 0
            };
        })
        dispatch(change('DespachoBodegaForm', `Despachos`, detalle))

    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const DespacharProducto = (data, orden, bodega) => (dispatch) => {
    dispatch(setLoader(true));
    data.orden = orden;
    data.bodega = bodega;
    api.post(`despachos/createdespachobodega`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset('DespachoBodegaForm'));
        if (!!dirGrid)
            dispatch(push(`/estado_bodega/${bodega}`));
            // dispatch(goBack())
    }).catch((error) => {
        let mensaje = 'Error en la creación del despacho';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

// Verificación de precios

const verificarPreciosProductos = () => (dispatch, getStore) => {
    const store = getStore().ordencompra
    const formData = _.cloneDeep(getStore().form.ordencompraForm.values);
    let productos = []
    formData.detalle_orden.forEach((item, key) => {
        if(item.producto)
            productos.push(item.producto.id)
    });
    if(productos.length > 0)
        dispatch(actualizarPrecios(productos, store.moneda, store.proveedor))
}

const actualizarPrecios = (productos, moneda, proveedor) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let data = {}
    if (productos) data.productos = productos
    if(moneda) data.moneda = moneda
    if(proveedor) data.proveedor = proveedor

    api.post(`productos/getProductoConComparacion`,data).then((response) => {
        if(response){
            const formData = _.cloneDeep(getStore().form.ordencompraForm.values);
            formData.detalle_orden.forEach((item, key) => {
              let select = _.find(response, x =>  x.id == item.producto.id)
              if(select){
                  item.precio_unitario = select.precio_ultimo
                  item.producto = select;
              }
            });
            dispatch(change('ordencompraForm', 'detalle_orden', formData.detalle_orden))

        }
        dispatch(setLoader(false));

    }).catch(() => {
    })
};
const setCliente = (cliente) => (dispatch) => {
    dispatch({
        type: constants.CLIENTE,
        data: cliente
    })
}

const clearData = () => (dispatch) => {
    dispatch(setData(constants.ITEM, {}));
    dispatch(initializeForm(form, {fecha: new Date()}));
    dispatch(initializeForm(form2, {fecha: new Date()}));
}

const setTMovimiento = (movimiento) => (dispatch) => {
    dispatch({type: constants.SET_TIPO_MOVIMIENTO, tipo_movimiento:movimiento});
}

const resetarForm = () => (dispatch) => {
    dispatch(reset('DevolucionProForm'));
    dispatch(reset('DevolucionForm'));
}

const generarPDFDTE = (dte_id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('factura/generateImprimibleDTE', {dte_id}).then((res)=>{
       if (res.detail){
           window.open(res.detail.factura, '_blank');
       }
    }).catch((error)=>{
        console.log(error)
        NotificationManager.error('Error en generar archivo.', 'ERROR', 3000);
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const setCheckNotaConIVA = (ckeck) => (dispatch, getStore) => {
    dispatch({type: constants.SET_CHECK_NOTA_CON_IVA, check_nota_con_iva: ckeck})
}


export const actions = {
    listar,
    leer,
    inicializarDespacho,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    setOpenModal,
    clearDespacho,
    reajustar_inventario,
    listDS,
    setProductoDespacho,
    DespacharProducto,
    getHistoria,
    selectEmpresas,
    verificarPreciosProductos,
    setCliente,
    aceptarDevoluciones,
    aceptarDevolucionesProv,
    rechazarDevolucion,
    realizarDespachos,
    realizarDespachosProv,
    clearData,
    HistoryDispatch,
    setTMovimiento,
    resetarForm,
    addProductos,
    removeProductos,
    generarPDFDTE,
    setCheckNotaConIVA,
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.OPEN_MODAL]: (state, { open_modal }) => {
        return {
            ...state,
            open_modal,
        };
    },
    [constants.LOADER_FORMULARIO]: (state, { loader_formulario }) => {
        return {
            ...state,
            loader_formulario,
        };
    },
    [constants.SET_PENDIENTES]:(state,{ data })=>{
        return{
            ...state,
            pendientes: data,
        }
    },
    [constants.SET_PRODUCTOSDS]:(state,{ data })=>{
        return{
            ...state,
            productosDS: data,
        }
    },
    [constants.HISTORIA_MOVIMIENTOS]:(state,{ data })=>{
        return{
            ...state,
            historia_movimientos: data,
        }
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data,
        }
    },
    [constants.SET_PRODUCTOS]:(state,{ data})=>{
        return{
            ...state,
            productos_add: data,
        }
    },
    [constants.CLIENTE]:(state,{ data })=>{
        return{
            ...state,
            cliente: data,
        }
    },
    [constants.SET_HISTORY]:(state,{ data })=>{
        return{
            ...state,
            history: data,
        }
    },
    [constants.SET_TIPO_MOVIMIENTO]:(state,{ tipo_movimiento })=>{
        return{
            ...state,
            tipo_movimiento,
        }
    },
    [constants.SET_CHECK_NOTA_CON_IVA]: (state, {check_nota_con_iva}) => {
        return {
            ...state,
            check_nota_con_iva
        }
    },
};


export const initialState ={
    loader:false,
    data: [],
    item: {nombre: ''},
    page: 1,
    ordering: '',
    search: '',
    open_modal: false,
    loader_formulario: false,
    pendientes:[],
    productosDS:[],
    empresas:[],
    cliente: null,
    historia_movimientos:{
        results: [],
        count: 0
    },
    history: [],
    productos_add: [],
    tipo_movimiento: 70,
    check_nota_con_iva: true,
};

export default handleActions(reducers, initialState);
