import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import ProductosGrid from './productoGrid';
import FacturasGrid from './facturasGrid'
// import TransporteDetalle from './transporteDetalle';
// import GastoGrid from './Gastos/gastosGrid';
import PagosContainer from './Pagos/pagocContainer';
import DespachoContainer from './Despacho/despachoContainer';
import DteContainer from './Dte/DteContainer';
// import ReintegroGrid from './Reintegros/reintegro';
// import OCpreview from './previewOC/OCpreview';

import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { getSimbolo} from "../../../Utils/renderField/renderTableField";

import {TIPO_ENTREGA} from "../../../../../utility/constants";
import LoadMask from "Utils/LoadMask/LoadMask";



export default class DetalleOC extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount() {
        let id = this.props.match.params.id
        this.props.leer(id);


    }
    getEntrega =(valor)=>{
        let entrega =''
        TIPO_ENTREGA.forEach((item,index)=>{
            if (item.value === valor){
                entrega = item.label
            }
        })
        return entrega
    }
    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar,cuentas, permisos } = this.props;
        let CPid = this.props.match.params.id
        let item = {orden: this.props.item};
        const facturas_string = item.orden.dtes.map(factura => factura.numero).join(', ')

        return (
            <div className="Container bg-white">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    {
                        (this.props.item) && (

                        <div className="m-t p-t fnt-size">
                            <div className="row m-none" style={{paddingTop:"15px"}}>
                                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                    <h3 className="t-primary m-none">{ item ? item.orden.no_despacho:""}</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                    <h3 className="t-primary m-none">{ item.orden ? item.orden.no_orden:""}</h3>
                                </div>
                            </div>
                            <div className="row m-none" style={{paddingTop:"15px"}}>
                                {/* <div className="row col-md-12  d-flex justify-content-between"> */}

                                    <div className="col-lg-3 col-sm-6 col-12 d-flex align-items-end">
                                        <span className="m-none t-mostaza semibold">Saldo a favor {item.orden.incluye_iva ? ' (Incluye IVA)' : ' (Sin IVA)'}:  </span>
                                        <span className="m-none t-musgo semibold">
                                            <RenderMoneda monto={item.orden.monto} simbolo={getSimbolo(this.props.moneda)}/>
                                        </span>
                                    </div>
                                {/* </div> */}
                            </div>
                            <br/>
                            {/* DATOS GENERALES */}
                            <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"10px 15px"}}/>
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Emitida por: </span><br/>
                                    <span className="m-none t-musgo semibold">{`${item.orden && item.orden.despachado_por ? item.orden.despachado_por.first_name : ''} ${item.orden && item.orden.despachado_por ? item.orden.despachado_por.last_name : ''}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Cliente: </span><br/>
                                    <span className="m-none t-musgo semibold">{`${item.orden.cliente ? item.orden.cliente.nombre : ''}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Fecha: </span><br/>
                                    <span className="m-none t-musgo semibold"><RenderDateTime fecha={item.orden.fecha} ></RenderDateTime></span>
                                </div>
                            </div>
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Orden Venta: </span><br/>
                                    <span className="m-none t-musgo semibold">{`${item.orden.orden && item.orden.orden.no_orden ? item.orden.orden.no_orden : ''}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Factura: </span><br/>
                                    <span className="m-none t-musgo semibold">{facturas_string}</span>
                                </div>
                            </div>
                            <br/>{/* DESCRIPCION */}
                            <div className="col-12">
                                <span className="m-none t-primary bold" >Descripción</span>
                            </div>
                            <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"0px 15px 10px 15px"}}/>
                            <div className="row m-none">
                                <div className="col-12">
                                    <span className="m-none t-musgo semibold">{`${item.orden.descripcion}`}</span>
                                </div>
                            </div>
                            <br/> {/*CONDICIONES DE ENTREGA */}
                            <br/> {/*  */}
                            <div className="row">
                                <div className="mb-4 col-12">
                                    <div className="mb-4 ">
                                        <div className=""><h6 className="m-0"></h6></div>
                                        <div className="p-0 px-3 pt-3 p-b">
                                        <Tabs
                                            defaultActiveKey="DETALLEOV"
                                            tabBarPoition = "top"
                                            onchange={this.callback}
                                            renderTabBar ={()=><ScrollableInkTabBar/>}
                                            renderTabContent={()=><TabContent/>}

                                        >
                                            <TabPane tab="PRODUCTOS" key="PRODUCTOS">
                                                <ProductosGrid
                                                    detalle_orden={item.orden.detalle_despacho}
                                                    moneda={'Q'}
                                                />
                                            </TabPane>
                                            {
                                                (permisos.devolucion_cli)  && (
                                                    <TabPane tab="DESPACHO" key="DESPACHO">
                                                        <DespachoContainer
                                                            data={this.props.history}
                                                            HistoryDispatch={this.props.HistoryDispatch}
                                                            AutorizarDespacho={this.props.AutorizarDespacho}
                                                            orden={item.orden}
                                                            id={this.props.match.params.id}
                                                            {...this.props}
                                                        />
                                                    </TabPane>
                                                )
                                            }
                                            {
                                                (permisos.devolucion_cli)  && (
                                                    <TabPane tab="DTE" key="DTE">
                                                        <DteContainer
                                                            dte_list={this.props.item.dtes}
                                                            id={this.props.match.params.id}
                                                            orden={item.orden}
                                                            generarPDFDTE={this.props.generarPDFDTE}
                                                        />
                                                    </TabPane>
                                                )
                                            }
                                        </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        )
                    }
                </LoadMask>
            </div>

        )
    }
}

