import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from "Utils/Grid/StandardActions";
import { RenderMoneda, RenderDateTime } from "Utils/renderField/renderTableField";
import { TIPOS_DESPACHOS } from '../../../../../utility/constants'
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';

export default class MovimientosGrid extends Component {
    componentWillMount(){
        this.props.selectTipos();
        this.props.selectUsuarios();
    }
    componentDidMount() {
        this.props.getHistoriaMovimientos(1, this.props.id_bodega);
    }

    pageChange = (page) => {
        this.props.getHistoriaMovimientos(page, this.props.id_bodega);
    }

    render() {
        const { historia_movimientos_despachos, loader, onSortChange, eliminar, page3 } = this.props;

        return (
            <Fragment>
                <Grid
                    data={historia_movimientos_despachos}
                    page={page3}
                    loading={loader}
                    onPageChange={this.pageChange}
                    onSortChange={onSortChange}
                    hover
                    striped
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        // dataSort
                        isKey
                        hidden
                        dataFormat={
                            standardActions({ editar: "e", eliminar })
                        }
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="despacho_fecha"
                        // dataSort
                        dataFormat={
                            (cell, row) => (
                                <RenderDateTime fecha={cell} />
                            )
                        }
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="producto"
                        // dataSort
                    >
                        PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="anterior"
                    >
                        ANTERIOR
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="despacho_detalle"
                        dataFormat={
                            (cell, row) => (
                                cell.cantidad
                            )
                        }
                    >
                        CANTIDAD
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="inventario"
                    >
                        INVENTARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="es_ingreso"
                        dataFormat={(cell, row) => {
                            if(cell.despacho_tipo_movimiento==50){
                                return "Reajuste"
                            }
                            else if(cell.despacho_tipo_movimiento==70){
                                return "Ingreso"
                            }
                            else if(cell){
                                return "Ingreso"
                            }
                            else{
                                return "Egreso"
                            }
                        }}
                    >
                        TIPO MOVIMIENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="origen_movimiento"
                        // dataSort
                        dataFormat={(cell) => {
                            const item = _.find(TIPOS_DESPACHOS, { value: cell });
                            return item ? item.label : 'Desconocido'
                        }}
                    >
                        ORIGEN MOVIMIENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="despacho_numero"
                        // dataSort
                    >
                        NO DESPACHO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="usuario"
                        // dataSort
                    >
                        REALIZADO POR
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }
}
