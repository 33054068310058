import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import {
    renderField,
    AsyncSelectField,
    renderFieldRadio,
    renderSelectField,
    renderCurrency,
    renderTextArea,
    renderSelecttree,
    SelectField,
    renderFieldCheck,
} from '../../../Utils/renderField';
import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";
import { LIMITE_MESES_GENERACION_NOTAS, DTE_NOTA_CREDITO, DTE_NOTA_ABONO } from '../../../../../utility/constants';
import {api} from 'api';


const getEmpresas = () => {
    console.log("entro empresas")
    return api.get("empresa/selectempresas").
    then((data) => {
        console.log("dara: ",data.empresa)
        if (data) return data.empresa;
        return [];
    }).catch(() => {
        return [];
    });
};

const getDespachos =(id_cliente)=>{
    console.log("id cliente: ", id_cliente)
    let params = {
        //tipo_movimiento: 40,
        //tipo_despacho: 10,
        orden__proveedor: id_cliente,
        //transaccion_despacho__tipo_transaccion: 13,
    }
    return api.get("despachos/listar_despachos2", params).
    then((data) => {
        console.log("data devul: ",data)
        if (data) return data;
        return [];
    }).catch(() => {
        return [];
    });
}

const changeMontoDescuento = (monto, change) => {
    const IVA = 0.12;
    const descuentoSinIVA = monto / (1.12);
    const descuentoIVA = descuentoSinIVA * IVA;

    // Actualiza los valores del Form en Redux Form
    change('descuento_iva', descuentoIVA.toFixed(2));
    change('descuento_neto', descuentoSinIVA.toFixed(2));
};

const checkNotaConIVA = (check, change) => {
    // Actualiza los valores del Form en Redux Form
    change('check_nota_con_iva', check);
};

let DescuentoForm = (props) => {
    const { handleSubmit, texto_boton_movimiento, simbolo, cerrarModal, idCliente, changeDocumento, tipo_nota,
        check_nota_con_iva, change } = props;

    let textoNotas = "";
    if (tipo_nota == null) textoNotas = '';
    else if (tipo_nota == 0) textoNotas = '*El Despacho no tiene Factura';
    else if (tipo_nota == DTE_NOTA_CREDITO) textoNotas = '*Se creará una Nota de Crédito';
    else textoNotas = '*Se creará una Nota de Abono';

    let notaUsaIva = [DTE_NOTA_ABONO].includes(tipo_nota) ? true : false;

    return (
        <form name="DescuentoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>

            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Fecha Descuento</label>
                    <Field
                        name="fecha"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Seleccionar Empresa*</label>
                    <Field
                        name="empresa"
                        label="empresa"
                        component={AsyncSelectField}
                        loadOptions={getEmpresas}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="no_despacho">Documento*</label>
                    <Field
                        name="despacho"
                        label="despacho"
                        component={AsyncSelectField}
                        loadOptions={() => getDespachos(props.idCliente)}
                        type="text"
                        valueKey="id"
                        labelKey="no_despacho"
                        className="form-control"
                        onChange={(e) => {
                            changeDocumento(e);
                        }}
                    />
                </div>
                <div className={`form-group has-feedback col-md-6 col-12`}>
                    <label className="t-azul" htmlFor="monto">Monto de Descuento*</label>
                    <Field
                        name="monto"
                        label="monto"
                        prefix={simbolo}
                        component={renderCurrency}
                        parse={cell => parseFloat(cell)}
                        className="form-control"
                        onChange={(e, newValue) => {
                            changeMontoDescuento(newValue, change);
                        }}
                    />
                </div>
            </div>

            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="descripcion">{textoNotas}</label>
                </div>
                {
                    notaUsaIva && (
                        <div className={`form-group has-feedback col-md-6 col-12`}>
                            <label className="t-azul" htmlFor="es_pago_anticipado">Con IVA&nbsp;&nbsp;</label>
                            <input
                                type="checkbox"
                                className="mt-1"
                                component={renderFieldCheck}
                                checked={check_nota_con_iva}
                                onChange={(e) => {
                                    checkNotaConIVA(e.target.checked, change)
                                }}
                            />
                            <div>
                                <span className="m-none t-mostaza semibold">IVA: </span>
                                <RenderMoneda monto={props.descuento_iva || 0} simbolo={simbolo} />
                            </div>
                            <div>
                            <span className="m-none t-mostaza semibold">Neto: </span>
                                <RenderMoneda monto={props.descuento_neto || 0} simbolo={simbolo} />
                            </div>
                        </div>
                    )
                }
            </div>

            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback col-sm-12 col-12">
                    <label className="t-azul" htmlFor="descripcion">Descripción</label>
                    <Field
                        name="descripcion"
                        label="descripcion"
                        component={renderTextArea}
                        type="text"
                        className="form-control" 
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around">
                        <button type="button" onClick={cerrarModal} className="btn btn-secondary align-self-center">CANCELAR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">
                            {texto_boton_movimiento ? texto_boton_movimiento : "AGREGAR DESCUENTO"}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

DescuentoForm = reduxForm({
    form: 'DescuentoForm',
    initialValues: {
        fecha: new Date(),
        fecha_cobro: new Date(),
        descuento_iva: 0,
        descuento_neto: 0,
        check_nota_con_iva: true,
    },
    validate: (data) => {
        const errors = {};

        if (!data.fecha) {
            errors.fecha = "Campo requerido.";
        }
        if (!data.no_documento) {
            errors.no_documento = "Campo requerido.";
        }
        if (!data.monto) {
            errors.monto = "Campo requerido.";
        }
        else {
            if (data.despacho && data.monto > data.despacho.monto_disponible) {
                errors.monto = `El monto debe ser menor o igual a Q. ${Number(data.despacho.monto_disponible).toFixed(2)}`;
            }
        }
        return errors
    },
})(DescuentoForm);

const selector = formValueSelector('DescuentoForm');
DescuentoForm = connect(
    (state) => ({
        descuento_iva: selector(state, 'descuento_iva'),
        descuento_neto: selector(state, 'descuento_neto'),
        check_nota_con_iva: selector(state, 'check_nota_con_iva'),
    }),
    { change } // Para usar change en props
)(DescuentoForm);

export default DescuentoForm;