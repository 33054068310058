import React, { Component } from 'react'
import DteGrid  from './DteGrid';
import {Link} from 'react-router-dom'


export default class DteContainer extends Component {
    render() {
        const { dte_list, generarPDFDTE } = this.props;
        return (
            <div>
                <DteGrid
                    dtes_list={dte_list}
                    generarPDFDTE={generarPDFDTE}
                />
            </div>
        )
    }
}
