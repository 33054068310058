import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';
import ImageEdit from '../../../../../assets/img/icons/editar.png';
import ImageDel from '../../../../../assets/img/icons/Cerrar.png';
import ImageVer from '../../../../../assets/img/icons/Ver.png';
import ImageAgen from '../../../../../assets/img/icons/agenda.png';
import ImageTransac from '../../../../../assets/img/icons/cuentas.png';
import ImagePago from '../../../../../assets/img/icons/marcar_bono.png';
import ImagenArchivero from '../../../../../assets/img/icons/Archivero.png';
import ImagenArchivo from '../../../../../assets/img/icons/Archivo.png';
import ImagenProduccionVenta from '../../../../../assets/img/icons/produccionventas.png';
import InicioProduccionVenta from '../../../../../assets/img/icons/convertirproduccion.png';
import ImagenCSV from '../../../../../assets/img/icons/CSV.png';
import Cuentas from '../../../../../assets/img/icons/Cuentas1.png';
import ImagenFacturacion from '../../../../../assets/img/icons/facturar.png';
import ImagenImprimirFactura from '../../../../../assets/img/icons/imprimir.png';
import ImagenImprimirDespacho from '../../../../../assets/img/icons/imprimir.png';
import ImagenAlerta from '../../../../../assets/img/icons/alarm.png'
import { iconos } from '../../../../utility/icons';
class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    };

    eliminarEspecial = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarEspecial(id);
                }
            });
        }
    };

    editarModal = (id) => {
        return () => {
            this.props.editarModal(id);
        }
    }

    eliminarpm = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarpm(id);
                }
            });
        }
    }; 

    eliminarModal(id, row){
        return () => {
            this.props.eliminarModal(id, row)
        }
    }
    anularFacturaModal(id, row){
        return () => {
            this.props.anularFacturaModal(id, row)
        }
    }
    verpagosModal(id, row){
        return () => {
            this.props.verpagosModal(id, row)
        }
    }
    alertModal(id, row){
        return () => {
            this.props.alertModal(id, row)
        }
    }

    eliminarAdicional=(id) =>{
        return () => {
            this.props.eliminarAdicional(id, row)
        }
    }
    bloquear_transaccion = (id) => {
        Swal.fire({
            title: '¿Desea bloquear la transacción?',
            text: '¡No podrá asignar esta transacción a un cliente!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, bloquear!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.candado(id);
            }
        });
    }
    desbloquear_transaccion = (id) => {
        Swal.fire({
            title: '¿Desea desbloquear la transacción?',
            text: '¡La transacción podrá ser asignada a un cliente!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, desbloquear',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.candado(id);
            }
        });
    };
    descargar = (id) => {
        this.props.descargar(id);
    }

    render() {
        const { id, ver, editar, editarModal, descargar,
            eliminar, eliminarEspecial,agenda, agendapro, transac,
            editCliente, editarItinerario, editProveedor,  adicional,
            eliminarAdicional, verpro,verpagos, verdespachoplanta, eliminarCC,
            row, eliminar2, eliminarModal,editarpm,verpagosModal,alertModal, anularFacturaModal,
            eliminarpm, vermodal, adicional2, adicional3, adicional4, adicional5,adicional6, historialCuenta,
            candado,gastoItinerario,verproVale, editarVale, generatePDFDTE,
            historialOrdenes, verproduccion, inicioProduccion, facturacion, verFactura, imprimirFactura, imprimirDespacho
        } = this.props;
        if(id === null || id === undefined){
            return(
                <div></div>
            );
        }
        return (
            <div className="d-sm-flex justify-content-sm-center flex-row justify-content-around align-content-between">
                {(adicional !== undefined) && adicional(id, row)}
                {(adicional2 !== undefined) && adicional2(id, row)}
                {(adicional3 !== undefined) && adicional3(id, row)}
                {(adicional6 !== undefined) && adicional6(id, row)}       
                {(generatePDFDTE !== undefined) && generatePDFDTE(id, row)}       
                {(verpagosModal !== undefined && row.estado) && (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.verpagosModal(id,row)}>
                    <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" />
                </a>
                )}    
                {(alertModal !== undefined && row.estado) && (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.alertModal(id,row)}>
                    <img className="action-img-alerta" title="alerta" src={ImagenAlerta} alt="alerta" />
                </a>
                )}
                {(anularFacturaModal !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.anularFacturaModal(id, row)}>
                        <img className="action-img" title="Anular" src={Cuentas} alt="Anular" />
                    </a>
                )}
                {(eliminar !== undefined) && (

                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.eliminar(id)}>
                        <img className="action-img" title="eliminar" src={ImageDel} alt="Delete" />
                    </a>
                )}
                {(eliminarEspecial !== undefined ) && (!row.aplicada) && (
                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.eliminarEspecial(id)}>
                        <img className="action-img" title="eliminar" src={ImageDel} alt="Delete" />
                    </a>
                )}
                {(eliminarpm !== undefined && row.proceso_estado !== 'Aceptado' ) && (
                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.eliminarpm(id)}>
                        <img className="action-img" title="eliminar" src={ImageDel} alt="Delete" />
                    </a>
                )}
                {(eliminarCC !== undefined && row.transaccion.tipo_transaccion !=70 ) && (
                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.eliminar(id)}>
                        <img className="action-img" title="eliminar" src={ImageDel} alt="Delete" />
                    </a>
                )}
                {(eliminarModal !== undefined && row.estado) && (
                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.eliminarModal(id, row)}>
                        <img className="action-img" title="eliminar" src={ImageDel} alt="Delete" />
                    </a>
                )}
                {(eliminarAdicional !== undefined ) && (
                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.eliminarAdicional(id, row)}>
                        <img className="action-img" title="eliminar" src={ImageDel} alt="Delete" />
                    </a>
                )}
                {(editar !== undefined) && (
                    <Link className="text-warning" to={`${editar}/${id}`} >
                        <img className="action-img" title="Editar" src={ImageEdit} alt="Edit" />
                    </Link>
                )}
                {(editarVale !== undefined && !row.cerrado && row.estado) && (
                    <Link className="text-warning" to={`${editarVale}/${id}`} >
                        <img className="action-img" title="Editar" src={ImageEdit} alt="Edit" />
                    </Link>
                )}
                {(editarModal !== undefined) && (!row.aplicada) && (
                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.editarModal(id)}>
                        <img className="action-img" title="Editar" src={ImageEdit} alt="Edit" />
                    </a>
                )}
                {(editarItinerario !== undefined && !row.cerrado) && (
                    <Link className="text-warning" to={`${editarItinerario}/${id}`} >
                        <img className="action-img" title="Editar" src={ImageEdit} alt="Edit" />
                    </Link>
                )}
                {(editCliente !== undefined) && (
                    <Link className="text-warning" to={`${editCliente}/${id}/editar`} >
                        <img className="action-img" title="Editar" src={ImageEdit} alt="Edit" />
                    </Link>
                )}
                {(editProveedor !== undefined) && (
                    <Link className="text-warning"
                        to={{
                            pathname: `${editProveedor}/${id}/editar`,
                            state: {
                                esCliente: false
                            }
                        }}
                    >
                        <img className="action-img" title="Editar" src={ImageEdit} alt="Edit" />
                    </Link>
                )}
                {(editarpm !== undefined && row.proceso_estado !='Aceptado') && (
                    <Link className="text-warning" to={`${editarpm}/${id}`} >
                        <img className="action-img" title="Editar" src={ImageEdit} alt="Edit" />
                    </Link>
                )}
                {(gastoItinerario !== undefined && !row.cerrado) && (
                    <Link to={`${gastoItinerario}/${id}/gastos`} className="pl-2" >
                        <img className="action-img-ver" title="Desglose" src={iconos.desglose_itinerario} alt="Desglose" />
                    </Link>
                )}
                {(ver !== undefined) && (
                    <Link to={`${ver}/${id}/`} className="px-2" >
                        <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" />
                    </Link>
                )}
                {(verproduccion !== undefined) && (
                    <Link to={`${verproduccion}/${id}/`} className="px-2" >
                        <img className="action-img-ver" title="ver" src={ImagenProduccionVenta} alt="ver" />
                    </Link>
                )}
                {(verpro !== undefined) && (
                    <Link to={`/${verpro}/${id}/detalle`} className="px-2" >
                        <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" />
                    </Link>
                )}
                {(verproVale !== undefined ) && (
                    <Link to={`/${verproVale}/${id}/detalle`} className="px-2" >
                        <img className="action-img-ver" title="ver" src={!row.estado || row.cerrado ? ImageVer : iconos.detalleVale} alt="ver" />
                    </Link>
                )}
                {(inicioProduccion !== undefined) && (
                    <Link to={`${inicioProduccion}/${id}`} className="px-2" >
                        <img className="action-img-ver" title="Generar OP" src={InicioProduccionVenta} alt="Generar OP" />
                    </Link>
                )}
                {(descargar !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={() => this.descargar(id)}>
                        <img className="action-img-ver" title="Descargar" src={ImagenCSV} alt="Descargar" />
                    </a>
                )}
                {(vermodal !== undefined) &&(

                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={ e => {
                        e.preventDefault()
                        vermodal(true, row)
                    }}>
                        <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" />
                    </a>
                )}
                {((candado !== undefined && row.flotante_inverso && row.estado && !row.asignado_a) | (candado !== undefined && row.transaccion.flotante_inverso && row.estado && !row.asignado_a)) ?(
                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={ e => {
                        e.preventDefault()
                        row.bloqueado ? this.desbloquear_transaccion(id) : this.bloquear_transaccion(id);
                    }}>
                        {
                            (row.bloqueado) ? (
                                <img className="action-img-ver" title="candado cerrado" src={iconos.candado_abierto} alt="Transacción bloqueada" />
                            ) : (
                                <img className="action-img-ver" title="candado abierto" src={iconos.candado_cerrado} alt="Transacción desbloqueada" />
                            )
                        }
                    </a>
                ):""}
                {(agenda !== undefined) && (

                    <Link to={`${agenda}/${id}/`} className="px-2" >
                        <img className="action-img-ver" title="agenda" src={ImageAgen} alt="Edit" />
                    </Link>
                )}
                {(agendapro !== undefined) && (
                    <Link
                        to={{
                            pathname: `${agendapro}/${id}/`,
                            state: {
                                esCliente: false
                            }
                        }}
                        className="px-2"
                    >
                        <img className="action-img-ver" title="agendapro" src={ImageAgen} alt="Edit" />
                    </Link>
                )}
                {(transac !== undefined) && (
                    <Link to={`${transac}/${id}/`} className="px-2" >
                        <img className="action-img-ver" title="transacciones" src={ImageTransac} alt="Edit" />
                    </Link>
                )}
                {(historialOrdenes !== undefined) && (
                    <Link to={`${historialOrdenes}/${row.id_clipro}/`} className="px-2" >
                        <img className="action-img-ver" title="Listado Ordenes" src={ImagenArchivo} alt="Listado Ordenes" />
                    </Link>
                )}
                {(historialCuenta !== undefined) && (
                    <Link to={`${historialCuenta}/${id}/`} className="px-2" >
                        <img className="action-img-ver" title="Historial de Cuenta" src={ImagenArchivero} alt="Historial de Cuenta" />
                    </Link>
                )}
                {(verdespachoplanta !== undefined) && (
                    <Link to={`/${verdespachoplanta}/${id}/detalle`} className="px-2" >
                        <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" />
                    </Link>
                )}
                {(facturacion !== undefined) && (
                    <Link to={`/${facturacion}/${id}/detalle`} className="px-2" >
                        <img className="action-img-ver" title="Facturación" src={ImagenFacturacion} alt="Facturación" />
                    </Link>
                )}
                {(verFactura !== undefined) && (
                    <Link to={`/${verFactura}/${id}/detalle`} className="px-2" >
                        <img className="action-img-ver" title="Ver Factura" src={ImageVer} alt="Ver Factura" />
                    </Link>
                )}
                {(imprimirFactura !== undefined) && (
                    <Link to={`/${imprimirFactura}/${id}/detalle`} className="px-2" >
                        <img className="action-img-ver" title="Imprimir Factura" src={ImagenImprimirFactura} alt="Imprimir Factura" />
                    </Link>
                )}
                {(imprimirDespacho !== undefined) && (
                    <Link to={`/${imprimirDespacho}/${id}/detalle`} className="px-2" >
                        <img className="action-img-ver" title="Imprimir Despacho" src={ImagenImprimirDespacho} alt="Imprimir Despacho" />
                    </Link>
                )}
                {(adicional4 !== undefined) && adicional4(id, row)}
                {(adicional5 !== undefined) && adicional5(id, row)}


            </div>
        );
    }
}
Acciones.propTypes = {
};

export function standardActions(acciones) {
    return (cell, row) => {
        return ( <Acciones id={cell} row={row} {...acciones}/> )
    };
}

export function standardActions2(acciones) {
    return ( <Acciones id={cell} {...acciones} row={acciones.row} contenido={acciones.row} /> )
}

export function customStandardActions(cell, acciones) {
    return <Acciones id={cell} {...acciones} />;
}
export function customStandardActions2(cell, row, acciones) {
    return <Acciones id={cell} row={row} {...acciones} />;
}
