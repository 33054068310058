export const Monedas = [
    {value:'USD', label:'USD', simbolo: '$'},
    {value:'GTQ', label:'GTQ', simbolo: 'Q'},
    {value: 'EUR', label:'EUR', simbolo:'€'},
];

export const QUETZAL = 'GTQ';

export const DIAS_FACTURACION = 15; // Dìas permitidos para realizar facturación
export const LIMITE_MESES_GENERACION_NOTAS = 2 // CANTIDAD MESES LIMITE PARA LA GENERACION DE NOTAS CREDITO/ABONOS

export const DTE_NOTA_CREDITO = 30;
export const DTE_NOTA_ABONO = 50;

export const getsimbolo = (valor)=>{
    let simbolo =''
    Monedas.forEach((item,index)=>{
        if (item.value === valor){
            simbolo = item.simbolo
        }
    })
    return simbolo + '. '
}

export const TiposCuentas = [
    {value:'MONETARIA', label:'Monetaria'},
    {value:'AHORRO', label:'Ahorro'},
]

export const MESES = [
    { value: 1, label: 'Enero'},
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril'},
    { value: 5, label: 'Mayo'},
    { value: 6, label: 'Junio'},
    { value: 7, label: 'Julio'},
    { value: 8, label: 'Agosto'},
    { value: 9, label: 'Septiembre'},
    { value: 10, label: 'Octubre'},
    { value: 11, label: 'Noviembre'},
    { value: 12, label: 'Diciembre'},
];

export const TiposRelacionado = [
    {value:true, label:'Cliente'},
    {value:false, label:'Proveedor'},
]

export const TiposContacto = [
    {value:1, label:'Proveedor'},
    {value:2, label:'Cliente'}
]

export const TIPO_VEHICULO = 1;
export const TIPO_MAQUINARIA = 5;

export const TIPO_PUESTO = [
    {value: 1, label:'Contratado'},
    {value: 5, label:'A Destajo'}
]

export const TIPO_GASOLINA = [
    {value: 1, label: "Diesel"},
    {value: 2, label: "Regular"},
    {value: 3, label: "Super"},
]

const electronica = 1;
const cheque = 2;
const deposito = 4;
const tarjeta = 5;
const efectivo = 6;

export const valores_tipo_transacciones = {
    electronica,
    cheque,
    deposito,
    tarjeta,
    efectivo
}
export const TipoTransaccion = [
    {value:1, label:'ELECTRONICA', ayuda: 'No. transacción'},
    {value:2, label:'CHEQUE', ayuda: 'No. cheque'},
    {value:4, label:'DEPOSITO', ayuda: 'No. boleta'},
    {value:5, label:'TARJETA', ayuda: 'No. transacción'},
]


export const TipoDocumentoPago = [
    {value:1, label:'Despacho con Factura'},
    {value:2, label:'Nota de Despacho'}
]

export const TIPO_ENTREGA = [
    {value: 1, label:'Recoger en planta'},
    {value: 2, label:'Entrega directa'},
    {value: 3, label:'Entrega por transporte externo'},
];
export const TIPO_ENTREGA_CLIENTE = [
    {value: 1, label:'Recoger en planta'},
    {value: 2, label:'Entrega directa'},
    {value: 3, label:'Entrega por transporte externo'},
];
export const TipoUnidades = [
    {value: 10, label:'Unidades'},
    {value: 20, label: 'Capacidad'},
    {value: 30, label: 'Peso'},
    {value: 40, label: 'Longitud'},
    {value: 50, label: 'Área'},
    {value: 60, label: 'Volumen'},
];
export const UnidadMedida = [
    // Unidad
    {value:1, label:'Unidades', unidad_medida: 10 },
    // Capacidades
    {value:5, label:'Mililitros', unidad_medida: 20},
    {value:10,label:'Litros', unidad_medida: 20},
    {value:15,label:'Galones', unidad_medida: 20},

    // Peso
    {value:20,label:'Miligramos', unidad_medida:30},
    {value:25,label:'Gramos', unidad_medida:30},
    {value:30,label:'Kilogramos', unidad_medida: 30},
    {value:35,label:'Onzas', unidad_medida: 30},
    {value:40,label:'Libras', unidad_medida:30},
    {value:45,label:'Arrobas', unidad_medida: 30},
    {value:50,label:'Quintales', unidad_medida: 30},
    {value:51,label:'Toneladas', unidad_medida: 30},

    // Longitud
    {value:55,label:'Milímetros', unidad_medida: 40},
    {value:60,label:'Centímetros', unidad_medida: 40},
    {value:65,label:'Metros', unidad_medida: 40},
    {value:70,label:'Pulgadas', unidad_medida: 40},
    {value:75,label:'Pies', unidad_medida: 40},
    {value:77,label:'Yardas', unidad_medida: 40},

    // Area
    {value:80,label:'Metros cuadrados', unidad_medida: 50},

    // Volumen.
    {value:85,label:'Metros cúbicos', unidad_medida: 60},
]

export const PROCESOS = [
    {value:100, label: 'Aceptado'},
    {value:90, label:'Espera'},
    {value:80, label:'Rechazado'},
    {value:60, label:'Enviado'},
    {value:10, label:'Borrador'}
]

export const T_MOVIMIENTOS = [
    {value:1, label:'INGRESO'},
    {value:2, label:'RETIRO'},
    {value:3, label:'REINTEGRO'},
    {value:4, label:'GASTO DE OC'},
    {value:5, label:'PAGO DE OC'},
    {value:6, label:'COBRO DE OV'},
    {value:7, label:'GASTO DE OV'},
    {value:8, label:'GASTOS GENERALES'},
    {value:9, label:'ABONO DE DEUDA'},
    {value:10, label:'AGREGAR DEUDA'},
    {value:201, label:'TRANSACCION DE CAJA CHICA'},
    {value:203, label:'TRANSACCION CUENTA BANCO'},
    {value:204, label:'TRANSACCION RETIRO EMPRESA'},
]

export const TIPOS_DESPACHOS = [
    {value:10, label: 'Ingreso Materia Prima'},
    {value:20, label:'Ingreso'},
    {value:30, label:'Despacho Bodega'},
    {value:40, label:'Despacho Venta'},
    {value:50, label:'Reajuste'},
    {value:60, label:'Baja'},
    {value:65, label:'Asignacion de repuesto a vehículo'},
    {value:70, label:'Devolución Cliente'},
    {value:75, label:'Devolución Proveedor'},
    {value:80, label:'Despacho Venta Interna'},
    {value:90, label:'Ingreso Devolución'},
    {value:95, label:'Despacho por Devolución Cliente'},
    {value:98, label:'Despacho por Devolución Proveedor'},
    {value:110, label:'Orden producción'},
    {value:120, label:'Orden producción MP'},
]

export const ESTADO_CIVIL = [
    {value:10, label: 'Soltero'},
    {value:20, label:'Casado'},
    {value:30, label:'Divorciado'},
    {value:40, label:'Viudo'},
]

export const CUENTA = [
    {value:10, label: 'Monetaria'},
    {value:20, label:'Ahorro'}
]

export const departamentos = [
    {value:'01', label:'Alta Verapaz'},
    {value:'02', label:'Baja Berapaz'},
    {value:'03', label:'Chimaltenango'},
    {value:'04', label:'Chiquimula'},
    {value:'05', label:'El Progreso'},
    {value:'06', label:'Escuintla'},
    {value:'07', label:'Guatemala'},
    {value:'08', label:'Huehuetenango'},
    {value:'09', label:'Izabal'},
    {value:'10', label:'Jalapa'},
    {value:'11', label:'Jutiapa'},
    {value:'12', label:'Petén'},
    {value:'13', label:'Quetzaltenango'},
    {value:'14', label:'Quiché'},
    {value:'15', label:'Retalhuleu'},
    {value:'16', label:'Sacatepéquez'},
    {value:'17', label:'San Marcos'},
    {value:'18', label:'Santa Rosa'},
    {value:'19', label:'Sololá'},
    {value:'20', label:'Suchitepéquez'},
    {value:'21', label:'Totonicapán'},
    {value:'22', label:'Zacapa'}
];

export const getMunicipios = (val) =>{
    let municipios_departamento = municipiosCodigos.filter(
        e => e.departamento === val
    )
    return municipios_departamento
}

export const municipios = [
    // ALTA VERAPAZ
    {value:'011', label: 'Chahal', departamento:"01"},
    {value:'012', label: 'Chisec', departamento:"01"},
    {value:'013', label: 'Cobán', departamento:"01"},
    {value:'014', label: 'Fray Bartolomé de las Casas', departamento:"01"},
    {value:'015', label: 'La Tinta', departamento:"01"},
    {value:'016', label: 'Lanquín', departamento:"01"},
    {value:'017', label: 'Panzós', departamento:"01"},
    {value:'018', label: 'Raxruhá', departamento:"01"},
    {value:'019', label: 'San Cristóbal Verapaz', departamento:"01"},
    {value:'0110', label: 'San Juan Chamelco', departamento:"01"},
    {value:'0111', label: 'San Pedro Carchá', departamento:"01"},
    {value:'0112', label: 'Santa Cruz Verapaz', departamento:"01"},
    {value:'0113', label: 'Santa María Cahabón', departamento:"01"},
    {value:'0114', label: 'Senahú', departamento:"01"},
    {value:'0115', label: 'Tamahú', departamento:"01"},
    {value:'0116', label: 'Tactic', departamento:"01"},
    {value:'0117', label: 'Tucurú', departamento:"01"},
    // Baja Verapaz
    {value:'021', label: 'Cubulco', departamento:"02"},
    {value:'022', label: 'Granados', departamento:"02"},
    {value:'023', label: 'Purulhá', departamento:"02"},
    {value:'024', label: 'Rabinal', departamento:"02"},
    {value:'025', label: 'Salamá', departamento:"02"},
    {value:'026', label: 'San Jerónimo', departamento:"02"},
    {value:'027', label: 'San Miguel Chicaj', departamento:"02"},
    {value:'028', label: 'Santa Cruz el Chol', departamento:"02"},
    // Chimaltenango
    {value:'031', label: 'Acatenango', departamento:"03"},
    {value:'032', label: 'Chimaltenango', departamento:"03"},
    {value:'033', label: 'El Tejar', departamento:"03"},
    {value:'034', label: 'Parramos', departamento:"03"},
    {value:'035', label: 'Patzicía', departamento:"03"},
    {value:'036', label: 'Patzún', departamento:"03"},
    {value:'037', label: 'Pochuta', departamento:"03"},
    {value:'038', label: 'San Andrés Itzapa', departamento:"03"},
    {value:'039', label: 'San José Poaquil', departamento:"03"},
    {value:'0310', label: 'San Juan Comalpa', departamento:"03"},
    {value:'0311', label: 'San Martín Jilotepeque', departamento:"03"},
    {value:'0312', label: 'Santa Apolonia', departamento:"03"},
    {value:'0313', label: 'Santa Cruz Balanyá', departamento:"03"},
    {value:'0314', label: 'Tecpán', departamento:"03"},
    {value:'0315', label: 'Yepocapa', departamento:"03"},
    {value:'0316', label: 'Zaragoza', departamento:"03"},
    // Chiquimula
    {value:'041', label: 'Camotán', departamento:"04"},
    {value:'042', label: 'Chiquimula', departamento:"04"},
    {value:'043', label: 'Concepción Las Minas', departamento:"04"},
    {value:'044', label: 'Esquipulas', departamento:"04"},
    {value:'045', label: 'Ipala', departamento:"04"},
    {value:'046', label: 'San Juan Ermita', departamento:"04"},
    {value:'047', label: 'Jocotán', departamento:"04"},
    {value:'048', label: 'Olopa', departamento:"04"},
    {value:'049', label: 'Quetzaltepeque', departamento:"04"},
    {value:'0410', label: 'San Jacinto', departamento:"04"},
    {value:'0411', label: 'San José la Arada', departamento:"04"},
    // El Progreso
    {value:'051', label: 'El Jícaro', departamento:"05"},
    {value:'052', label: 'Guastatoya', departamento:"05"},
    {value:'053', label: 'Morazán', departamento:"05"},
    {value:'054', label: 'San Agustín Acasaguastlán', departamento:"05"},
    {value:'058', label: 'San Antonio La Paz', departamento:"05"},
    {value:'056', label: 'San Cristóbal Acasaguastlán', departamento:"05"},
    {value:'057', label: 'Sanarate', departamento:"05"},
    {value:'058', label: 'Sansare', departamento:"05"},
    // Escuintla
    {value:'061', label: 'Escuintla', departamento:"06"},
    {value:'062', label: 'Guanagazapa', departamento:"06"},
    {value:'063', label: 'Iztapa', departamento:"06"},
    {value:'064', label: 'La Democracia', departamento:"06"},
    {value:'065', label: 'La Gomera', departamento:"06"},
    {value:'066', label: 'Masagua', departamento:"06"},
    {value:'067', label: 'Nueva Concepción', departamento:"06"},
    {value:'068', label: 'Palín', departamento:"06"},
    {value:'069', label: 'San José', departamento:"06"},
    {value:'0610', label: 'San Vincente Pacaya', departamento:"06"},
    {value:'0611', label: 'Santa Lucía Cotzumalguapa', departamento:"06"},
    {value:'0612', label: 'Sipacate', departamento:"06"},
    {value:'0613', label: 'Siquinilá', departamento:"06"},
    {value:'0614', label: 'Tiquisate', departamento:"06"},
    // Guatemala
    {value:'071', label: 'Amatitlán', departamento:"07"},
    {value:'072', label: 'Chinautla', departamento:"07"},
    {value:'073', label: 'Chuarrancho', departamento:"07"},
    {value:'074', label: 'Ciudad de Guatemala', departamento:"07"},
    {value:'075', label: 'Frajanes', departamento:"07"},
    {value:'076', label: 'Mixco', departamento:"07"},
    {value:'077', label: 'Palencia', departamento:"07"},
    {value:'078', label: 'San José del Golfo', departamento:"07"},
    {value:'079', label: 'San José Pinula', departamento:"07"},
    {value:'0710', label: 'San Juan Sacatepéquez', departamento:"07"},
    {value:'0711', label: 'San Miguel Petapa', departamento:"07"},
    {value:'0712', label: 'San Pedro Ayampuc', departamento:"07"},
    {value:'0713', label: 'San Pedro Sacatepéquez', departamento:"07"},
    {value:'0714', label: 'San Raymundo', departamento:"07"},
    {value:'0715', label: 'Santa Catarina Pinula', departamento:"07"},
    {value:'0716', label: 'Villa Canales', departamento:"07"},
    {value:'0717', label: 'Villa Nueva', departamento:"07"},
    // Huehuetenango
    {value:'081', label: 'Aguacatán', departamento:"08"},
    {value:'082', label: 'Chiantla', departamento:"08"},
    {value:'083', label: 'Colotenango', departamento:"08"},
    {value:'084', label: 'Concepción Huista', departamento:"08"},
    {value:'085', label: 'Cuilco', departamento:"08"},
    {value:'086', label: 'Huehuetenango', departamento:"08"},
    {value:'087', label: 'Jacaltenango', departamento:"08"},
    {value:'088', label: 'La Democracia', departamento:"08"},
    {value:'089', label: 'La Libertad', departamento:"08"},
    {value:'0810', label: 'Malacatancito', departamento:"08"},
    {value:'0811', label: 'Nentón', departamento:"08"},
    {value:'0812', label: 'Petatán', departamento:"08"},
    {value:'0813', label: 'San Antonio Huista', departamento:"08"},
    {value:'0814', label: 'San Gaspar Ixchil', departamento:"08"},
    {value:'0815', label: 'San Ildefonso Ixtahuacán', departamento:"08"},
    {value:'0816', label: 'San Juan Atitán', departamento:"08"},
    {value:'0817', label: 'San Juan Ixcoy', departamento:"08"},
    {value:'0818', label: 'San Mateo Ixtatán', departamento:"08"},
    {value:'0819', label: 'San Miguel Acatán', departamento:"08"},
    {value:'0820', label: 'San Pedro Nécta', departamento:"08"},
    {value:'0821', label: 'San Pedro Soloma', departamento:"08"},
    {value:'0822', label: 'San Rafael La Independencia', departamento:"08"},
    {value:'0823', label: 'San Rafael Pétzal', departamento:"08"},
    {value:'0824', label: 'San Sebastián Coatán', departamento:"08"},
    {value:'0825', label: 'San Sebastián Huehuetenango', departamento:"08"},
    {value:'0826', label: 'Santa Ana Huista', departamento:"08"},
    {value:'0827', label: 'Santa Bárbara', departamento:"08"},
    {value:'0828', label: 'Santa Cruz Barillas', departamento:"08"},
    {value:'0829', label: 'Santa Eulalia', departamento:"08"},
    {value:'0830', label: 'Santiago Chimaltenango', departamento:"08"},
    {value:'0831', label: 'Tectitán', departamento:"08"},
    {value:'0832', label: 'Todos Santos Cuchumatán', departamento:"08"},
    {value:'0833', label: 'Unión Cantinil', departamento:"08"},
    // Izabal
    {value:'091', label: 'El Estor', departamento:"09"},
    {value:'092', label: 'Livingston', departamento:"09"},
    {value:'093', label: 'Los Amates', departamento:"09"},
    {value:'094', label: 'Morales', departamento:"09"},
    {value:'095', label: 'Puerto Barrios', departamento:"09"},
    // Jalapa
    {value:'101', label: 'Jalapa', departamento:"10"},
    {value:'102', label: 'Mataquescuintla', departamento:"10"},
    {value:'103', label: 'Monjas', departamento:"10"},
    {value:'104', label: 'San Carlos Alzatate', departamento:"10"},
    {value:'105', label: 'San Luis Jilotepeque', departamento:"10"},
    {value:'106', label: 'San Manuel Chaparrón', departamento:"10"},
    {value:'107', label: 'San Pedro Pinula', departamento:"10"},
    // Jutiapa
    {value:'111', label: 'Agua Blanca', departamento:"11"},
    {value:'112', label: 'Asunción Mita', departamento:"11"},
    {value:'113', label: 'Atescatempa', departamento:"11"},
    {value:'114', label: 'Comapa', departamento:"11"},
    {value:'115', label: 'Conguaco', departamento:"11"},
    {value:'116', label: 'El Adelanto', departamento:"11"},
    {value:'117', label: 'El Progreso', departamento:"11"},
    {value:'118', label: 'Jalpatagua', departamento:"11"},
    {value:'119', label: 'Jerez', departamento:"11"},
    {value:'1110', label: 'Jutiapa', departamento:"11"},
    {value:'1111', label: 'Moyuta', departamento:"11"},
    {value:'1112', label: 'Pasaco', departamento:"11"},
    {value:'1113', label: 'Quesada', departamento:"11"},
    {value:'1114', label: 'San José Acatempa', departamento:"11"},
    {value:'1115', label: 'Santa Catarina Mita', departamento:"11"},
    {value:'1116', label: 'Yupiltepeque', departamento:"11"},
    {value:'1117', label: 'Zapotitlán', departamento:"11"},
    // Petén
    {value:'121', label: 'Dolores', departamento:"12"},
    {value:'122', label: 'El Chal', departamento:"12"},
    {value:'123', label: 'Flores', departamento:"12"},
    {value:'124', label: 'La Libertad', departamento:"12"},
    {value:'125', label: 'Las Cruces', departamento:"12"},
    {value:'126', label: 'Melchor de Mencos', departamento:"12"},
    {value:'127', label: 'Poptún', departamento:"12"},
    {value:'128', label: 'San Andrés', departamento:"12"},
    {value:'129', label: 'San Benito', departamento:"12"},
    {value:'1210', label: 'San Francisco', departamento:"12"},
    {value:'1211', label: 'San José', departamento:"12"},
    {value:'1212', label: 'San Luis', departamento:"12"},
    {value:'1213', label: 'Santa Ana', departamento:"12"},
    {value:'1214', label: 'Sayaxché', departamento:"12"},
    // Quetzaltenango
    {value:'131', label: 'Almolonga', departamento:"13"},
    {value:'132', label: 'Cabricán', departamento:"13"},
    {value:'133', label: 'Cajolá', departamento:"13"},
    {value:'134', label: 'Cantel', departamento:"13"},
    {value:'135', label: 'Coatepeque', departamento:"13"},
    {value:'136', label: 'Colomba Costa Cuca', departamento:"13"},
    {value:'137', label: 'Concepción Chiquirichapa', departamento:"13"},
    {value:'138', label: 'El Palmar', departamento:"13"},
    {value:'139', label: 'Flores Costa Cuca', departamento:"13"},
    {value:'1310', label: 'Génova', departamento:"13"},
    {value:'1311', label: 'Huitán', departamento:"13"},
    {value:'1312', label: 'La Esperanza', departamento:"13"},
    {value:'1313', label: 'Olintepeque', departamento:"13"},
    {value:'1314', label: 'Palestina de Los Altos', departamento:"13"},
    {value:'1315', label: 'Quetzaltenango', departamento:"13"},
    {value:'1316', label: 'Salcajá', departamento:"13"},
    {value:'1317', label: 'San Carlos Sija', departamento:"13"},
    {value:'1318', label: 'San Francisco La Unión', departamento:"13"},
    {value:'1319', label: 'San Juan Ostuncalco', departamento:"13"},
    {value:'1320', label: 'San Martín Sacatepéquez', departamento:"13"},
    {value:'1321', label: 'San Mateo', departamento:"13"},
    {value:'1322', label: 'San Miguel Sigüilá', departamento:"13"},
    {value:'1323', label: 'Sibilia', departamento:"13"},
    {value:'1324', label: 'Zunil', departamento:"13"},
    // Quiché
    {value:'141', label: 'Canillá', departamento:"14"},
    {value:'142', label: 'Chajul', departamento:"14"},
    {value:'143', label: 'Chicamán', departamento:"14"},
    {value:'144', label: 'Chiché', departamento:"14"},
    {value:'145', label: 'Santo Tomás Chichicastenango', departamento:"14"},
    {value:'146', label: 'Chinique', departamento:"14"},
    {value:'147', label: 'Cunén', departamento:"14"},
    {value:'148', label: 'Ixcán', departamento:"14"},
    {value:'149', label: 'Joyabaj', departamento:"14"},
    {value:'1410', label: 'Nebaj', departamento:"14"},
    {value:'1411', label: 'Pachalum', departamento:"14"},
    {value:'1412', label: 'Patzité', departamento:"14"},
    {value:'1413', label: 'Sacapulas', departamento:"14"},
    {value:'1414', label: 'San Andrés Sajcabajá', departamento:"14"},
    {value:'1415', label: 'San Antonio Ilotenango', departamento:"14"},
    {value:'1416', label: 'San Bartolomé Jocotenango', departamento:"14"},
    {value:'1417', label: 'San Juan Cotzal', departamento:"14"},
    {value:'1418', label: 'San Pedro Jocopilas', departamento:"14"},
    {value:'1419', label: 'Santa Cruz del Quiché', departamento:"14"},
    {value:'1420', label: 'Uspantán', departamento:"14"},
    {value:'1421', label: 'Zacualpa', departamento:"14"},
    // Retalhuleu
    {value:'151', label: 'Champerico', departamento:"15"},
    {value:'152', label: 'El Asintal', departamento:"15"},
    {value:'153', label: 'Nuevo San Carlos', departamento:"15"},
    {value:'154', label: 'Retalhuleu', departamento:"15"},
    {value:'155', label: 'San Andrés Villa Seca', departamento:"15"},
    {value:'156', label: 'San Felipe', departamento:"15"},
    {value:'157', label: 'San Martín Zapotitlán', departamento:"15"},
    {value:'158', label: 'San Sebastián', departamento:"15"},
    {value:'159', label: 'Santa Cruz Muluá', departamento:"15"},
    // Sacatepéquez
    {value:'161', label: 'Alotenango', departamento:"16"},
    {value:'162', label: 'Ciudad Vieja', departamento:"16"},
    {value:'163', label: 'Jocotenango', departamento:"16"},
    {value:'164', label: 'Antigua Guatemala', departamento:"16"},
    {value:'165', label: 'Magdalena Milpas Altas', departamento:"16"},
    {value:'166', label: 'Pastores', departamento:"16"},
    {value:'167', label: 'San Antonio Aguas Calientes', departamento:"16"},
    {value:'168', label: 'San Bartolomé Milpas Altas', departamento:"16"},
    {value:'169', label: 'San Lucas Sacatepéquez', departamento:"16"},
    {value:'1610', label: 'San Miguel Dueñas', departamento:"16"},
    {value:'1611', label: 'Santa Catarina Barahona', departamento:"16"},
    {value:'1612', label: 'Santa Lucía Milpas Altas', departamento:"16"},
    {value:'1613', label: 'Santa María de Jesús', departamento:"16"},
    {value:'1614', label: 'Santiago Sacatepéquez', departamento:"16"},
    {value:'1615', label: 'Santo Domingo Xenacoj', departamento:"16"},
    {value:'1616', label: 'Sumpango', departamento:"16"},
    // San Marcos
    {value:'171', label:'Ayutla', departamento:"17"},
    {value:'172', label:'Catarina', departamento:"17"},
    {value:'173', label:'Comitancillo', departamento:"17"},
    {value:'174', label:'Concepción Tutuapa', departamento:"17"},
    {value:'175', label:'El Quetzal', departamento:"17"},
    {value:'176', label:'El Tumbador', departamento:"17"},
    {value:'177', label:'Esquipulas Palo Gordo', departamento:"17"},
    {value:'178', label:'Ixchiguán', departamento:"17"},
    {value:'179', label:'La Blanca', departamento:"17"},
    {value:'1710', label:'La Reforma', departamento:"17"},
    {value:'1711', label:'Malacatán', departamento:"17"},
    {value:'1712', label:'Nuevo Progreso', departamento:"17"},
    {value:'1713', label:'Ocós', departamento:"17"},
    {value:'1714', label:'Pajapita', departamento:"17"},
    {value:'1715', label:'Río Blanco', departamento:"17"},
    {value:'1716', label:'San Antonio Sacatepéquez', departamento:"17"},
    {value:'1717', label:'San Cristóbal Cucho', departamento:"17"},
    {value:'1718', label:'San José El Rodeo', departamento:"17"},
    {value:'1719', label:'San José Ojetenam', departamento:"17"},
    {value:'1720', label:'San Lorenzo', departamento:"17"},
    {value:'1721', label:'San Marcos', departamento:"17"},
    {value:'1722', label:'San Miguel Ixtahuacán', departamento:"17"},
    {value:'1723', label:'San Pablo', departamento:"17"},
    {value:'1724', label:'San Pedro Sacatepéquez', departamento:"17"},
    {value:'1725', label:'San Rafael Pie de la Cuesta', departamento:"17"},
    {value:'1726', label:'Sibinal', departamento:"17"},
    {value:'1727', label:'Sipacapa', departamento:"17"},
    {value:'1728', label:'Tacaná', departamento:"17"},
    {value:'1729', label:'Tajumulco', departamento:"17"},
    {value:'1730', label:'Tejutla', departamento:"17"},
    // Santa Rosa
    {value:'181', label: 'Barberena', departamento:"18"},
    {value:'182', label: 'Casillas', departamento:"18"},
    {value:'183', label: 'Chiquimulilla', departamento:"18"},
    {value:'184', label: 'Cuilapa', departamento:"18"},
    {value:'185', label: 'Guazacapán', departamento:"18"},
    {value:'186', label: 'Nueva Santa Rosa', departamento:"18"},
    {value:'187', label: 'Oratorio', departamento:"18"},
    {value:'188', label: 'Pueblo Nuevo Viñas', departamento:"18"},
    {value:'189', label: 'San Juan Tecuaco', departamento:"18"},
    {value:'1810', label: 'San Rafael las Flores', departamento:"18"},
    {value:'1811', label: 'Santa Cruz Naranjo', departamento:"18"},
    {value:'1812', label: 'Santa María Ixhuatán', departamento:"18"},
    {value:'1813', label: 'Santa Rosa de Lima', departamento:"18"},
    {value:'1814', label: 'Taxisco', departamento:"18"},
    // Sololá
    {value:'191', label: 'Concepción', departamento:"19"},
    {value:'192', label: 'Nahualá', departamento:"19"},
    {value:'193', label: 'Panajachel', departamento:"19"},
    {value:'194', label: 'San Andrés Semetabaj', departamento:"19"},
    {value:'195', label: 'San Antonio Palopó', departamento:"19"},
    {value:'196', label: 'San José Chacayá', departamento:"19"},
    {value:'197', label: 'San Juan La Laguna', departamento:"19"},
    {value:'198', label: 'San Lucas Tolimán', departamento:"19"},
    {value:'199', label: 'San Marcos La Laguna', departamento:"19"},
    {value:'1910', label: 'San Pablo La Laguna', departamento:"19"},
    {value:'1911', label: 'San Pedro La Laguna', departamento:"19"},
    {value:'1912', label: 'Santa Catarina Ixtahuacán', departamento:"19"},
    {value:'1913', label: 'Santa Catarina Palopó', departamento:"19"},
    {value:'1914', label: 'Santa Clara La Laguna', departamento:"19"},
    {value:'1915', label: 'Santa Cruz La Laguna', departamento:"19"},
    {value:'1916', label: 'Santa Lucía Utatlán', departamento:"19"},
    {value:'1917', label: 'Santa María Visitación', departamento:"19"},
    {value:'1918', label: 'Santiago Atitlán', departamento:"19"},
    {value:'1919', label: 'Sololá', departamento:"19"},
    // Suchitepéquez
    {value:'201', label: 'Chicacao', departamento:"20"},
    {value:'202', label: 'Cuyotenango', departamento:"20"},
    {value:'203', label: 'Mazatenango', departamento:"20"},
    {value:'204', label: 'Patulul', departamento:"20"},
    {value:'205', label: 'Pueblo Nuevo', departamento:"20"},
    {value:'206', label: 'Río Bravo', departamento:"20"},
    {value:'207', label: 'Samayac', departamento:"20"},
    {value:'208', label: 'San Antonio Suchitepéquez', departamento:"20"},
    {value:'209', label: 'San Bernardino', departamento:"20"},
    {value:'2010', label: 'San Francisco Zapotitlán', departamento:"20"},
    {value:'2011', label: 'San Gabriel', departamento:"20"},
    {value:'2012', label: 'San José El valueolo', departamento:"20"},
    {value:'2013', label: 'San José La Máquina', departamento:"20"},
    {value:'2014', label: 'San Juan Bautista', departamento:"20"},
    {value:'2015', label: 'San Lorenzo', departamento:"20"},
    {value:'2016', label: 'San Miguel Panán', departamento:"20"},
    {value:'2017', label: 'San Pablo Jocopilas', departamento:"20"},
    {value:'2018', label: 'Santa Bárbara', departamento:"20"},
    {value:'2019', label: 'Santo Domingo Suchitepéquez', departamento:"20"},
    {value:'2020', label: 'Santo Tomás La Unión', departamento:"20"},
    {value:'2021', label: 'Zunilito', departamento:"20"},
    // Totonicapán
    {value:'211', label: 'Momostenango', departamento:"21"},
    {value:'212', label: 'San Andrés Xecul', departamento:"21"},
    {value:'213', label: 'San Bartolo', departamento:"21"},
    {value:'214', label: 'San Cristóbal Totonicapán', departamento:"21"},
    {value:'215', label: 'San Francisco El Alto', departamento:"21"},
    {value:'216', label: 'Santa Lucía La Reforma', departamento:"21"},
    {value:'217', label: 'Santa María Chiquimula', departamento:"21"},
    {value:'218', label: 'Totonicapán', departamento:"21"},
    // Zacapa
    {value:'221',label: 'Cabañas', departamento:"22"},
    {value:'222',label: 'Estanzuela', departamento:"22"},
    {value:'223',label: 'Gualán', departamento:"22"},
    {value:'224',label: 'Huité', departamento:"22"},
    {value:'225',label: 'La Unión', departamento:"22"},
    {value:'226',label: 'Río Hondo', departamento:"22"},
    {value:'227',label: 'San Diego', departamento:"22"},
    {value:'228',label: 'San Jorge', departamento:"22"},
    {value:'229',label: 'Teculután', departamento:"22"},
    {value:'2210',label: 'Usumatlán', departamento:"22"},
    {value:'2211',label: 'Zacapa', departamento:"22"}
];



export const municipiosCodigos = 	[
    {value:'011', label: 'Chahal', departamento:"01",codigo: "16014"},
    {value:'012', label: 'Chisec', departamento:"01",codigo: "16013"},
    {value:'013', label: 'Cobán', departamento:"01",codigo: "16001"},
    {value:'014', label: 'Fray Bartolomé de las Casas', departamento:"01",codigo: "16015"},
    {value:'015', label: 'La Tinta', departamento:"01",codigo: "16016"},
    {value:'016', label: 'Lanquín', departamento:"01",codigo: 16011},
    {value:'017', label: 'Panzós', departamento:"01",codigo: 16007},
    {value:'018', label: 'Raxruhá', departamento:"01",codigo: 16026},
    {value:'019', label: 'San Cristóbal Verapaz', departamento:"01",codigo: 16003},
    {value:'0110', label: 'San Juan Chamelco', departamento:"01",codigo: 16010},
    {value:'0111', label: 'San Pedro Carchá', departamento:"01",codigo: 16009},
    {value:'0112', label: 'Santa Cruz Verapaz', departamento:"01",codigo: 16002},
    {value:'0113', label: 'Santa María Cahabón', departamento:"01",codigo: 16012},
    {value:'0114', label: 'Senahú', departamento:"01",codigo: 16008},
    {value:'0115', label: 'Tamahú', departamento:"01",codigo: 16005},
    {value:'0116', label: 'Tactic', departamento:"01",codigo: 16004},
    {value:'0117', label: 'Tucurú', departamento:"01",codigo: 16006},
    // Baja Verapaz
    {value:'021', label: 'Cubulco', departamento:"02",codigo: 15004},
    {value:'022', label: 'Granados', departamento:"02",codigo: 15005},
    {value:'023', label: 'Purulhá', departamento:"02",codigo: 15008},
    {value:'024', label: 'Rabinal', departamento:"02",codigo: 15003},
    {value:'025', label: 'Salamá', departamento:"02",codigo: 15001},
    {value:'026', label: 'San Jerónimo', departamento:"02",codigo: 15007},
    {value:'027', label: 'San Miguel Chicaj', departamento:"02",codigo: 15002},
    {value:'028', label: 'Santa Cruz el Chol', departamento:"02",codigo: 15006},
    // Chimaltenango
    {value:'031', label: 'Acatenango', departamento:"03", codigo: "04011"},
    {value:'032', label: 'Chimaltenango', departamento:"03",codigo: "04001"},
    {value:'033', label: 'El Tejar', departamento:"03",codigo: "04016"},
    {value:'034', label: 'Parramos', departamento:"03",codigo: "04014"},
    {value:'035', label: 'Patzicía', departamento:"03",codigo: "04009"},
    {value:'036', label: 'Patzún', departamento:"03",codigo: "04007"},
    {value:'037', label: 'Pochuta', departamento:"03",codigo: "04008"},
    {value:'038', label: 'San Andrés Itzapa', departamento:"03",codigo: "04013"},
    {value:'039', label: 'San José Poaquil', departamento:"03",codigo: "04002"},
    {value:'0310', label: 'San Juan Comalapa', departamento:"03",codigo: "04004"},
    {value:'0311', label: 'San Martín Jilotepeque', departamento:"03",codigo: "04003"},
    {value:'0312', label: 'Santa Apolonia', departamento:"03",codigo: "04005"},
    {value:'0313', label: 'Santa Cruz Balanyá', departamento:"03",codigo: "04010"},
    {value:'0314', label: 'Tecpán', departamento:"03",codigo: "04006"},
    {value:'0315', label: 'Yepocapa', departamento:"03",codigo: "04012"},
    {value:'0316', label: 'Zaragoza', departamento:"03",codigo: "04015"},
    // Chiquimula
    {value:'041', label: 'Camotán', departamento:"04",codigo: 20005},
    {value:'042', label: 'Chiquimula', departamento:"04",codigo: 20001},
    {value:'043', label: 'Concepción Las Minas', departamento:"04",codigo: 20008},
    {value:'044', label: 'Esquipulas', departamento:"04",codigo: 20007},
    {value:'045', label: 'Ipala', departamento:"04",codigo: 20011},
    {value:'046', label: 'San Juan Ermita', departamento:"04",codigo: 20003},
    {value:'047', label: 'Jocotán', departamento:"04",codigo: 20004},
    {value:'048', label: 'Olopa', departamento:"04",codigo: 20006},
    {value:'049', label: 'Quetzaltepeque', departamento:"04",codigo: 20009},
    {value:'0410', label: 'San Jacinto', departamento:"04",codigo: 20010},
    {value:'0411', label: 'San José la Arada', departamento:"04",codigo: 20002},
    // El Progreso
    {value:'051', label: 'El Jícaro', departamento:"05",codigo: "02005"},
    {value:'052', label: 'Guastatoya', departamento:"05",codigo: "02001"},
    {value:'053', label: 'Morazán', departamento:"05",codigo: "02002"},
    {value:'054', label: 'San Agustín Acasaguastlán', departamento:"05",codigo: "02003"},
    {value:'058', label: 'San Antonio La Paz', departamento:"05",codigo: "02008"},
    {value:'056', label: 'San Cristóbal Acasaguastlán', departamento:"05",codigo: "02004"},
    {value:'057', label: 'Sanarate', departamento:"05",codigo: "02007"},
    {value:'058', label: 'Sansare', departamento:"05",codigo: "02006"},
    // Escuintla
    {value:'061', label: 'Escuintla', departamento:"06" ,codigo: "05001"},
    {value:'062', label: 'Brito(Guanagazapa)', departamento:"06",codigo: "05015"},
    {value:'063', label: 'Itzapa', departamento:"06",codigo: "05010"},
    {value:'064', label: 'La Democracia', departamento:"06",codigo: "05003"},
    {value:'065', label: 'La Gomera', departamento:"06",codigo: "05007"},
    {value:'066', label: 'Masagua', departamento:"06",codigo: "05005"},
    {value:'067', label: 'Nueva Concepción', departamento:"06",codigo: "05013"},
    {value:'068', label: 'Palín', departamento:"06",codigo: "05011"},
    {value:'069', label: 'San José', departamento:"06",codigo: "05009"},
    {value:'0610', label: 'San Vincente Pacaya', departamento:"06",codigo: "05012"},
    {value:'0611', label: 'Santa Lucía Cotzumalguapa', departamento:"06",codigo: "05002"},
    {value:'0612', label: 'Sipacate', departamento:"06",codigo: "05021"},
    {value:'0613', label: 'Siquinalá', departamento:"06",codigo: "05004"},
    {value:'0614', label: 'Tiquisate', departamento:"06",codigo: "05006"},
    // Guatemala
    {value:'071', label: 'Amatitlán', departamento:"07", codigo: "01063"},
    {value:'072', label: 'Chinautla', departamento:"07", codigo: "01055"},
    {value:'073', label: 'Chuarrancho', departamento:"07", codigo: "01061"},
    {value:'074', label: 'Ciudad de Guatemala', departamento:"07", codigo: "01001"},
    {value:'075', label: 'Fraijanes', departamento:"07", codigo: "01062"},
    {value:'076', label: 'Mixco', departamento:"07", codigo: "01057"},
    {value:'077', label: 'Palencia', departamento:"07", codigo: "01054"},
    {value:'078', label: 'San José del Golfo', departamento:"07", codigo: "01053"},
    {value:'079', label: 'San José Pinula', departamento:"07", codigo: "01052"},
    {value:'0710', label: 'San Juan Sacatepéquez', departamento:"07", codigo: "01059"},
    {value:'0711', label: 'San Miguel Petapa', departamento:"07", codigo: "01066"},
    {value:'0712', label: 'San Pedro Ayampuc', departamento:"07", codigo: "01056"},
    {value:'0713', label: 'San Pedro Sacatepéquez', departamento:"07", codigo: "01058"},
    {value:'0714', label: 'San Raymundo', departamento:"07", codigo: "01060"},
    {value:'0715', label: 'Santa Catarina Pinula', departamento:"07", codigo: "01051"},
    {value:'0716', label: 'Villa Canales', departamento:"07", codigo: "01065"},
    {value:'0717', label: 'Villa Nueva', departamento:"07", codigo: "01064"},
    // Huehuetenango
    {value:'081', label: 'Aguacatán', departamento:"08", codigo: 13027},
    {value:'082', label: 'Chiantla', departamento:"08", codigo: 13002},
    {value:'083', label: 'Colotenango', departamento:"08", codigo: 13019},
    {value:'084', label: 'Concepción Huista', departamento:"08", codigo: 13022},
    {value:'085', label: 'Cuilco', departamento:"08", codigo: 13004},
    {value:'086', label: 'Huehuetenango', departamento:"08", codigo: 13001},
    {value:'087', label: 'Jacaltenango', departamento:"08", codigo: 13007},
    {value:'088', label: 'La Democracia', departamento:"08", codigo: 13012},
    {value:'089', label: 'La Libertad', departamento:"08", codigo: 13011},
    {value:'0810', label: 'Malacatancito', departamento:"08", codigo: 13003},
    {value:'0811', label: 'Nentón', departamento:"08", codigo: 13005},
    {value:'0812', label: 'Petatán', departamento:"08", codigo: 13036},
    {value:'0813', label: 'San Antonio Huista', departamento:"08", codigo: 13024},
    {value:'0814', label: 'San Gaspar Ixchil', departamento:"08", codigo: 13029},
    {value:'0815', label: 'San Ildefonso Ixtahuacán', departamento:"08", codigo: 13009},
    {value:'0816', label: 'San Juan Atitán', departamento:"08", codigo: 13016},
    {value:'0817', label: 'San Juan Ixcoy', departamento:"08", codigo: 13023},
    {value:'0818', label: 'San Mateo Ixtatán', departamento:"08", codigo: 13018},
    {value:'0819', label: 'San Miguel Acatán', departamento:"08", codigo: 13013},
    {value:'0820', label: 'San Pedro Nécta', departamento:"08", codigo: 13006},
    {value:'0821', label: 'San Pedro Soloma', departamento:"08", codigo: 13008},
    {value:'0822', label: 'San Rafael La Independencia', departamento:"08", codigo: 13014},
    {value:'0823', label: 'San Rafael Pétzal', departamento:"08", codigo: 13028},
    {value:'0824', label: 'San Sebastián Coatán', departamento:"08", codigo: 13025},
    {value:'0825', label: 'San Sebastián Huehuetenango', departamento:"08", codigo: 13020},
    {value:'0826', label: 'Santa Ana Huista', departamento:"08", codigo: 13031},
    {value:'0827', label: 'Santa Bárbara', departamento:"08", codigo: 13010},
    {value:'0828', label: 'Santa Cruz Barillas', departamento:"08", codigo: 13026},
    {value:'0829', label: 'Santa Eulalia', departamento:"08", codigo: 13017},
    {value:'0830', label: 'Santiago Chimaltenango', departamento:"08", codigo: 13030},
    {value:'0831', label: 'Tectitán', departamento:"08", codigo: 13021},
    {value:'0832', label: 'Todos Santos Cuchumatán', departamento:"08", codigo: 13034},
    // {value:'0833', label: 'Unión Cantinil', departamento:"08", codigo: 000000},
    // Izabal
    {value:'091', label: 'El Estor', departamento:"09", codigo: 18003},
    {value:'092', label: 'Livingston', departamento:"09", codigo: 18002},
    {value:'093', label: 'Los Amates', departamento:"09", codigo: 18005},
    {value:'094', label: 'Morales', departamento:"09", codigo: 18004},
    {value:'095', label: 'Puerto Barrios', departamento:"09", codigo: 18001},
    // Jalapa
    {value:'101', label: 'Jalapa', departamento:"10", codigo: 21001},
    {value:'102', label: 'Mataquescuintla', departamento:"10", codigo: 21007},
    {value:'103', label: 'Monjas', departamento:"10", codigo: 21006},
    {value:'104', label: 'San Carlos Alzatate', departamento:"10", codigo: 21005},
    {value:'105', label: 'San Luis Jilotepeque', departamento:"10", codigo: 21003},
    {value:'106', label: 'San Manuel Chaparrón', departamento:"10", codigo: 21004},
    {value:'107', label: 'San Pedro Pinula', departamento:"10", codigo: 21002},
    // Jutiapa
    {value:'111', label: 'Agua Blanca', departamento:"11", codigo: 22004},
    {value:'112', label: 'Asunción Mita', departamento:"11", codigo: 22005},
    {value:'113', label: 'Atescatempa', departamento:"11", codigo: 22007},
    {value:'114', label: 'Comapa', departamento:"11", codigo: 22011},
    {value:'115', label: 'Conguanco', departamento:"11", codigo: 22013},
    {value:'116', label: 'El Adelanto', departamento:"11", codigo: 22009},
    {value:'117', label: 'El Progreso', departamento:"11", codigo: 22002},
    {value:'118', label: 'Jalpatagua', departamento:"11", codigo: 22012},
    {value:'119', label: 'Jerez', departamento:"11", codigo: 22008},
    {value:'1110', label: 'Jutiapa', departamento:"11", codigo: 22001},
    {value:'1111', label: 'Moyuta', departamento:"11", codigo: 22014},
    {value:'1112', label: 'Pasaco', departamento:"11", codigo: 22015},
    {value:'1113', label: 'Quesada', departamento:"11", codigo: 22017},
    {value:'1114', label: 'San José Acatempa', departamento:"11", codigo: 22016},
    {value:'1115', label: 'Santa Catarina Mita', departamento:"11", codigo: 22003},
    {value:'1116', label: 'Yupiltepeque', departamento:"11", codigo: 22006},
    {value:'1117', label: 'Zapotitlán', departamento:"11", codigo: 22010},
    // Petén
    {value:'121', label: 'Dolores', departamento:"12", codigo: 17008},
    {value:'122', label: 'El Chal', departamento:"12", codigo: 17020},
    {value:'123', label: 'Flores', departamento:"12", codigo: 17001},
    {value:'124', label: 'La Libertad', departamento:"12", codigo: 17005},
    {value:'125', label: 'Las Cruces', departamento:"12", codigo: 17027},
    {value:'126', label: 'Melchor de Mencos', departamento:"12", codigo: 17011},
    {value:'127', label: 'Poptún', departamento:"12", codigo: 17012},
    {value:'128', label: 'San Andrés', departamento:"12", codigo: 17004},
    {value:'129', label: 'San Benito', departamento:"12", codigo: 17003},
    {value:'1210', label: 'San Francisco', departamento:"12", codigo: 17006},
    {value:'1211', label: 'San José', departamento:"12", codigo: 17002},
    {value:'1212', label: 'San Luis', departamento:"12", codigo: 17009},
    {value:'1213', label: 'Santa Ana', departamento:"12", codigo: 17007},
    {value:'1214', label: 'Sayaxché', departamento:"12", codigo: 17010},
    // Quetzaltenango
    {value:'131', label: 'Almolonga', departamento:"13", codigo: "09013"},
    {value:'132', label: 'Cabricán', departamento:"13", codigo: "09006"},
    {value:'133', label: 'Cajolá', departamento:"13", codigo: "09007"},
    {value:'134', label: 'Cantel', departamento:"13", codigo: "09014"},
    {value:'135', label: 'Coatepeque', departamento:"13", codigo: "09020"},
    {value:'136', label: 'Colomba Costa Cuca', departamento:"13", codigo: "09017"},
    {value:'137', label: 'Concepción Chiquirichapa', departamento:"13", codigo: "09011"},
    {value:'138', label: 'El Palmar', departamento:"13", codigo: "09019"},
    {value:'139', label: 'Flores Costa Cuca', departamento:"13", codigo: "09022"},
    {value:'1310', label: 'Génova', departamento:"13", codigo: "09021"},
    {value:'1311', label: 'Huitán', departamento:"13", codigo: "09015"},
    {value:'1312', label: 'La Esperanza', departamento:"13", codigo: "09023"},
    {value:'1313', label: 'Olintepeque', departamento:"13", codigo: "09003"},
    {value:'1314', label: 'Palestina de Los Altos', departamento:"13", codigo: "09024"},
    {value:'1315', label: 'Quetzaltenango', departamento:"13", codigo: "09001"},
    {value:'1316', label: 'Salcajá', departamento:"13", codigo: "09002"},
    {value:'1317', label: 'San Carlos Sija', departamento:"13", codigo: "09004"},
    {value:'1318', label: 'San Francisco La Unión', departamento:"13", codigo: "09018"},
    {value:'1319', label: 'San Juan Ostuncalco', departamento:"13", codigo: "09009"},
    {value:'1320', label: 'San Martín Sacatepéquez', departamento:"13", codigo: "09012"},
    {value:'1321', label: 'San Mateo', departamento:"13", codigo: "09010"},
    {value:'1322', label: 'San Miguel Sigüilá', departamento:"13", codigo: "09008"},
    {value:'1323', label: 'Sibilia', departamento:"13", codigo: "09005"},
    {value:'1324', label: 'Zunil', departamento:"13", codigo: "09016"},
    // Quiché
    {value:'141', label: 'Canillá', departamento:"14", codigo: 14018},
    {value:'142', label: 'Chajul', departamento:"14", codigo: 14005},
    {value:'143', label: 'Chicamán', departamento:"14", codigo: 14020},
    {value:'144', label: 'Quiché', departamento:"14", codigo: 14002},
    {value:'145', label: 'Santo Tomás Chichicastenango', departamento:"14", codigo: 14006},
    {value:'146', label: 'Chinique', departamento:"14", codigo: 14003},
    {value:'147', label: 'Cunén', departamento:"14", codigo: 14010},
    {value:'148', label: 'Playa grande(Ixcán)', departamento:"14", codigo: 14019},
    {value:'149', label: 'Joyabaj', departamento:"14", codigo: 14012},
    {value:'1410', label: 'Nebaj', departamento:"14", codigo: 14013},
    {value:'1411', label: 'Pachalum', departamento:"14", codigo: 14022},
    {value:'1412', label: 'Patzité', departamento:"14", codigo: 14007},
    {value:'1413', label: 'Sacapulas', departamento:"14", codigo: 14016},
    {value:'1414', label: 'San Andrés Sajcabajá', departamento:"14", codigo: 14014},
    {value:'1415', label: 'San Antonio Ilotenango', departamento:"14", codigo: 14008},
    {value:'1416', label: 'San Bartolomé Jocotenango', departamento:"14", codigo: 14017},
    {value:'1417', label: 'San Juan Cotzal', departamento:"14", codigo: 14011},
    {value:'1418', label: 'San Pedro Jocopilas', departamento:"14", codigo: 14009},
    {value:'1419', label: 'Santa Cruz del Quiché', departamento:"14", codigo: 14001},
    {value:'1420', label: 'Uspantán', departamento:"14", codigo: 14015},
    {value:'1421', label: 'Zacualpa', departamento:"14", codigo: 14004},
    // Retalhuleu
    {value:'151', label: 'Champerico', departamento:"15", codigo: 11007},
    {value:'152', label: 'El Asintal', departamento:"15", codigo: 11009},
    {value:'153', label: 'Nuevo San Carlos', departamento:"15", codigo: 11008},
    {value:'154', label: 'Retalhuleu', departamento:"15", codigo: 11001},
    {value:'155', label: 'San Andrés Villa Seca', departamento:"15", codigo: 11006},
    {value:'156', label: 'San Felipe', departamento:"15", codigo: 11005},
    {value:'157', label: 'San Martín Zapotitlán', departamento:"15", codigo: 11004},
    {value:'158', label: 'San Sebastián', departamento:"15", codigo: 11002},
    {value:'159', label: 'Santa Cruz Muluá', departamento:"15", codigo: 11003},
    // Sacatepéquez
    {value:'161', label: 'Alotenango', departamento:"16", codigo: "03014"},
    {value:'162', label: 'Ciudad Vieja', departamento:"16", codigo: "03012"},
    {value:'163', label: 'Jocotenango', departamento:"16", codigo: "03002"},
    {value:'164', label: 'Antigua Guatemala', departamento:"16", codigo: "03001"},
    {value:'165', label: 'Magdalena Milpas Altas', departamento:"16", codigo: "03010"},
    {value:'166', label: 'Pastores', departamento:"16", codigo: "03003"},
    {value:'167', label: 'San Antonio Aguas Calientes', departamento:"16", codigo: "03015"},
    {value:'168', label: 'San Bartolomé Milpas Altas', departamento:"16", codigo: "03007"},
    {value:'169', label: 'San Lucas Sacatepéquez', departamento:"16", codigo: "03008"},
    {value:'1610', label: 'San Miguel Dueñas', departamento:"16", codigo: "03013"},
    {value:'1611', label: 'Santa Catarina Barahona', departamento:"16", codigo: "03016"},
    {value:'1612', label: 'Santa Lucía Milpas Altas', departamento:"16", codigo: "03009"},
    {value:'1613', label: 'Santa María de Jesús', departamento:"16", codigo: "03011"},
    {value:'1614', label: 'Santiago Sacatepéquez', departamento:"16", codigo: "03006"},
    {value:'1615', label: 'Santo Domingo Xenacoj', departamento:"16", codigo: "03005"},
    {value:'1616', label: 'Sumpango', departamento:"16", codigo: "03004"},
    // San Marcos
    {value:'171', label:'Ayutla', departamento:"17", codigo: 12017},
    {value:'172', label:'Catarina', departamento:"17", codigo: 12016},
    {value:'173', label:'Comitancillo', departamento:"17", codigo: 12004},
    {value:'174', label:'Concepción Tutuapa', departamento:"17", codigo: 12006},
    {value:'175', label:'El Quetzal', departamento:"17", codigo: 12020},
    {value:'176', label:'El Tumbador', departamento:"17", codigo: 12013},
    {value:'177', label:'Esquipulas Palo Gordo', departamento:"17", codigo: 12027},
    {value:'178', label:'Ixchiguán', departamento:"17", codigo: 12023},
    {value:'179', label:'La Blanca', departamento:"17", codigo: 12039},
    {value:'1710', label:'La Reforma', departamento:"17", codigo: 12021},
    {value:'1711', label:'Malacatán', departamento:"17", codigo: 12015},
    {value:'1712', label:'Nuevo Progreso', departamento:"17", codigo: 12012},
    {value:'1713', label:'Ocós', departamento:"17", codigo: 12018},
    {value:'1714', label:'Pajapita', departamento:"17", codigo: 12022},
    {value:'1715', label:'Río Blanco', departamento:"17", codigo: 12028},
    {value:'1716', label:'San Antonio Sacatepéquez', departamento:"17", codigo: 12003},
    {value:'1717', label:'San Cristóbal Cucho', departamento:"17", codigo: 12025},
    {value:'1718', label:'San José El Rodeo', departamento:"17", codigo: 12014},
    {value:'1719', label:'San José Ojetenam', departamento:"17", codigo: 12024},
    {value:'1720', label:'San Lorenzo', departamento:"17", codigo: 12029},
    {value:'1721', label:'San Marcos', departamento:"17", codigo: 12001},
    {value:'1722', label:'San Miguel Ixtahuacán', departamento:"17", codigo: 12005},
    {value:'1723', label:'San Pablo', departamento:"17", codigo: 12019},
    {value:'1724', label:'San Pedro Sacatepéquez', departamento:"17", codigo: 12002},
    {value:'1725', label:'San Rafael Pie de la Cuesta', departamento:"17", codigo: 12011},
    {value:'1726', label:'Sibinal', departamento:"17", codigo: 12008},
    {value:'1727', label:'Sipacapa', departamento:"17", codigo: 12026},
    {value:'1728', label:'Tacaná', departamento:"17", codigo: 12007},
    {value:'1729', label:'Tajumulco', departamento:"17", codigo: 12009},
    {value:'1730', label:'Tejutla', departamento:"17", codigo: 12010},
    // Santa Rosa
    {value:'181', label: 'Barberena', departamento:"18", codigo: "06002"},
    {value:'182', label: 'Casillas', departamento:"18", codigo: "06004"},
    {value:'183', label: 'Chiquimulilla', departamento:"18", codigo: "06008"},
    {value:'184', label: 'Cuilapa', departamento:"18", codigo: "06001"},
    {value:'185', label: 'Guazacapán', departamento:"18", codigo: "06011"},
    {value:'186', label: 'Nueva Santa Rosa', departamento:"18", codigo: "06014"},
    {value:'187', label: 'Oratorio', departamento:"18", codigo: "06006"},
    {value:'188', label: 'Pueblo Nuevo Viñas', departamento:"18", codigo: "06013"},
    {value:'189', label: 'San Juan Tecuaco', departamento:"18", codigo: "06007"},
    {value:'1810', label: 'San Rafael las Flores', departamento:"18", codigo: "06005"},
    {value:'1811', label: 'Santa Cruz Naranjo', departamento:"18", codigo: "06012"},
    {value:'1812', label: 'Santa María Ixhuatán', departamento:"18", codigo: "06010"},
    {value:'1813', label: 'Santa Rosa de Lima', departamento:"18", codigo: "06003"},
    {value:'1814', label: 'Taxisco', departamento:"18", codigo: "06009"},
    // Sololá
    {value:'191', label: 'Concepción', departamento:"19", codigo: "07008"},
    {value:'192', label: 'Nahualá', departamento:"19", codigo: "07005"},
    {value:'193', label: 'Panajachel', departamento:"19", codigo: "07010"},
    {value:'194', label: 'San Andrés Semetabaj', departamento:"19", codigo: "07009"},
    {value:'195', label: 'San Antonio Palopó', departamento:"19", codigo: "07012"},
    {value:'196', label: 'San José Chacayá', departamento:"19", codigo: "07002"},
    {value:'197', label: 'San Juan La Laguna', departamento:"19", codigo: "07017"},
    {value:'198', label: 'San Lucas Tolimán', departamento:"19", codigo: "07013"},
    {value:'199', label: 'San Marcos La Laguna', departamento:"19", codigo: "07016"},
    {value:'1910', label: 'San Pablo La Laguna', departamento:"19", codigo: "07015"},
    {value:'1911', label: 'San Pedro La Laguna', departamento:"19", codigo: "07018"},
    {value:'1912', label: 'Santa Catarina Ixtahuacán', departamento:"19", codigo: "07006"},
    {value:'1913', label: 'Santa Catarina Palopó', departamento:"19", codigo: "07011"},
    {value:'1914', label: 'Santa Clara La Laguna', departamento:"19", codigo: "07007"},
    {value:'1915', label: 'Santa Cruz La Laguna', departamento:"19", codigo: "07014"},
    {value:'1916', label: 'Santa Lucía Utatlán', departamento:"19", codigo: "07004"},
    {value:'1917', label: 'Santa María Visitación', departamento:"19", codigo: "07003"},
    {value:'1918', label: 'Santiago Atitlán', departamento:"19", codigo: "07019"},
    {value:'1919', label: 'Sololá', departamento:"19", codigo: "07001"},
    // Suchitepéquez
    {value:'201', label: 'Chicacao', departamento:"20", codigo: 10013},
    {value:'202', label: 'Cuyotenango', departamento:"20", codigo: 10002},
    {value:'203', label: 'Mazatenango', departamento:"20", codigo: 10001},
    {value:'204', label: 'Patulul', departamento:"20", codigo: 10014},
    {value:'205', label: 'Pueblo Nuevo', departamento:"20", codigo: 10019},
    {value:'206', label: 'Río Bravo', departamento:"20", codigo: 10020},
    {value:'207', label: 'Samayac', departamento:"20", codigo: 10008},
    {value:'208', label: 'San Antonio Suchitepéquez', departamento:"20", codigo: 10010},
    {value:'209', label: 'San Bernardino', departamento:"20", codigo: 10004},
    {value:'2010', label: 'San Francisco Zapotitlán', departamento:"20", codigo: 10003},
    {value:'2011', label: 'San Gabriel', departamento:"20", codigo: 10012},
    // {value:'2012', label: 'San José El valueolo', departamento:"20", codigo: 000000},
    {value:'2013', label: 'San José La Máquina', departamento:"20", codigo: 10030},
    {value:'2014', label: 'San Juan Bautista', departamento:"20", codigo: 10016},
    {value:'2015', label: 'San Lorenzo', departamento:"20", codigo: 10007},
    {value:'2016', label: 'San Miguel Panán', departamento:"20", codigo: 10011},
    {value:'2017', label: 'San Pablo Jocopilas', departamento:"20", codigo: 10009},
    {value:'2018', label: 'Santa Bárbara', departamento:"20", codigo: 10015},
    {value:'2019', label: 'Santo Domingo Suchitepéquez', departamento:"20", codigo: 10006},
    {value:'2020', label: 'Santo Tomás La Unión', departamento:"20", codigo: 10017},
    {value:'2021', label: 'Zunilito', departamento:"20", codigo: 10018},
    // Totonicapán
    {value:'211', label: 'Momostenango', departamento:"21", codigo: "08005"},
    {value:'212', label: 'San Andrés Xecul', departamento:"21", codigo: "08004"},
    {value:'213', label: 'San Bartolo', departamento:"21", codigo: "08008"},
    {value:'214', label: 'San Cristóbal Totonicapán', departamento:"21", codigo: "08002"},
    {value:'215', label: 'San Francisco El Alto', departamento:"21", codigo: "08003"},
    {value:'216', label: 'Santa Lucía La Reforma', departamento:"21", codigo: "08007"},
    {value:'217', label: 'Santa María Chiquimula', departamento:"21", codigo: "08006"},
    {value:'218', label: 'Totonicapán', departamento:"21", codigo: "08001"},
    // Zacapa
    {value:'221',label: 'Cabañas', departamento:"22", codigo: 19007},
    {value:'222',label: 'Estanzuela', departamento:"22", codigo: 19002},
    {value:'223',label: 'Gualán', departamento:"22", codigo: 19004},
    {value:'224',label: 'Huité', departamento:"22", codigo: 19010},
    {value:'225',label: 'La Unión', departamento:"22", codigo: 19009},
    {value:'226',label: 'Río Hondo', departamento:"22", codigo: 19003},
    {value:'227',label: 'San Diego', departamento:"22", codigo: 19008},
    {value:'228',label: 'San Jorge', departamento:"22", codigo: 19013},
    {value:'229',label: 'Teculután', departamento:"22", codigo: 19005},
    {value:'2210',label: 'Usumatlán', departamento:"22", codigo: 19006},
    {value:'2211',label: 'Zacapa', departamento:"22", codigo: 19001},
]
