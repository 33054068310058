import React, { Component, Fragment } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import CardFormulario from 'Utils/Cards/cardFormulario';

// Componentes extra
import DevolucionForm from './DevolucionForm';
import moment from 'moment';
import DetalleDespacho from '../Detalle/detalleOV';
import Swal from 'sweetalert2';

import { LIMITE_MESES_GENERACION_NOTAS, DTE_NOTA_CREDITO, DTE_NOTA_ABONO } from '../../../../../utility/constants';

export default class DevolucionCrear extends Component {
    state = {
        step: 1,
        titulo: 'CREAR DEVOLUCIÓN',
        editar: false
    }
    componentWillMount() {
        this.props.selectEmpresas();
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.setState({
                editar:true,
                titulo: 'VERIFICAR DEVOLUCIÓN'
            });
            this.props.leer(this.props.match.params.id, true)
        }
    }

    componentWillUnmount(){
        this.props.clearData();
    }

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };

    onSubmit = (values) =>{
        const {crear, aceptarDevoluciones, check_nota_con_iva, tipoNota} = this.props;
        let id = this.props.match.params.id
        let valores = _.cloneDeep(values);
        if(valores.detalle_despacho){
            valores.detalle_despacho.forEach( x => {
                x.producto = x.producto.id
            })
        }

        if(valores.depacho_devolucion){
            valores.depacho_devolucion = valores.depacho_devolucion.id;
        }
        if(valores.despachado_por){
            valores.despachado_por = valores.despachado_por.id;
        }
        valores.cliente = valores.cliente.id;
        valores.encargado = valores.encargado.id;
        valores.empresa = Number(valores.empresa);
        valores.fecha = moment(valores.fecha).format("YYYY-MM-D");
        if (this.state.editar) valores.nota_con_iva = check_nota_con_iva ? check_nota_con_iva : false;

        this.state.editar ? aceptarDevoluciones(valores) : crear(valores);
    };

    anular = () => {
        const id = this.props.match.params.id;
        Swal.fire({
            title: '¿Desea rechazar esta devolución?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, rechazar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.rechazarDevolucion(id, false);
            }
        });
    };

    render() {
        const { loader, empresas, item, permisos, check_nota_con_iva, setCheckNotaConIVA } = this.props;
        let hoy = moment();
        let tipoNota = 0;

        // Tipo de nota que se generará
        try {
            if (item.depacho_devolucion && (!item.depacho_devolucion.factura_despacho || item.depacho_devolucion.factura_despacho['id'] == undefined)) {
                tipoNota = 0;
            }
            else {
                let fechaCertificacion = moment(item.depacho_devolucion.factura_despacho.fecha_certificacion, 'YYYY-MM-DD');
                let fechaTemp = new Date(fechaCertificacion.year(), fechaCertificacion.month(), 1);
                let fechaLimite = new Date(fechaTemp.setMonth(fechaTemp.getMonth() + LIMITE_MESES_GENERACION_NOTAS+1));
                fechaLimite.setDate(fechaLimite.getDate() - 1);

                if (hoy <= fechaLimite) {
                    tipoNota = DTE_NOTA_CREDITO;
                }
                else tipoNota = DTE_NOTA_ABONO;
            }
        } catch (e) {
            console.log(e)
        }

        let notaUsaIva = [DTE_NOTA_ABONO].includes(tipoNota) ? true : false;

        return (
            <Fragment>
                {
                    (this.state.editar) ? (
                        <Fragment>
                            {

                                (permisos.devolucion_cli) ? (
                                    (item.flujo_despacho == 20) ? (
                                        <LoadMask loading={loader} light type={"TailSpin"}>
                                            <DetalleDespacho {...this.props} />
                                        </LoadMask>
                                    ): (
                                        <CardFormulario titulo={this.state.titulo}>
                                            <LoadMask loading={loader} light type={"TailSpin"}>
                                                <DevolucionForm
                                                    {...this.props}
                                                    check_nota_con_iva={check_nota_con_iva}
                                                    setCheckNotaConIVA={setCheckNotaConIVA}
                                                    tipoNota={tipoNota}
                                                    notaUsaIva={notaUsaIva}
                                                    initialValues={{fecha: new Date()}}
                                                    empresas={empresas}
                                                    editar={this.state.editar}
                                                    onSubmit={this.onSubmit}
                                                    anular={this.anular}
                                                />
                                            </LoadMask>
                                        </CardFormulario>
                                    )
                                ) : (
                                    <LoadMask loading={loader} light type={"TailSpin"}>
                                        <DetalleDespacho {...this.props} />
                                    </LoadMask>
                                )
                            }
                        </Fragment>
                    ) : (
                        <CardFormulario titulo={this.state.titulo}>
                            <LoadMask loading={loader} light type={"TailSpin"}>
                                <DevolucionForm
                                    {...this.props}
                                    initialValues={{fecha: new Date()}}
                                    empresas={empresas}
                                    tipoNota={tipoNota}
                                    editar={this.state.editar}
                                    onSubmit={this.onSubmit}/>
                            </LoadMask>
                        </CardFormulario>
                    )
                }
            </Fragment>
        );
    }
}
